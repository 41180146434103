<script lang="ts">
import { cn } from '../utils/shadcnUtils'

import ZIcon from './ZIcon.svelte'
import NuIconClose from './icons/NuIconClose.svelte'

// -------------------------------------------------------------------

let {
  value = $bindable(),
  placeholder = undefined,
  type = undefined,
  shouldFocus = false,
  allowPasswordManagerIcons = false,
  callbackData = undefined,
  callbackInputCleared = undefined,
  callbackValChanged = undefined,
  ...props
}: {
  value: string
  placeholder?: string
  type?: string
  shouldFocus?: boolean
  allowPasswordManagerIcons?: boolean
  callbackData?: unknown
  callbackInputCleared?: (callbackData: unknown) => void
  callbackValChanged?: (val: string, callbackData: unknown) => void
  class?: string
} = $props()

// -------------------------------------------------------------------

const extraAttrs = allowPasswordManagerIcons
  ? {}
  : {
      'data-lpignore': 'true',
      'data-1p-ignore': true
    }

const showClearButton = !allowPasswordManagerIcons

let inputElement: HTMLInputElement | null = $state(null)

// -------------------------------------------------------------------

const className = $derived.by(() => {
  const cls = typeof props.class === 'string' ? props.class : ''
  return cls.replace('h1', 'text-2xl font-extrabold font-title +-[input]:pb-[2px]')
})

// -------------------------------------------------------------------

function valChanged (e: Event): void {
  if (callbackValChanged !== undefined) {
    const target = e.target as HTMLInputElement
    callbackValChanged(target.value, callbackData)
  }
}

// -------------------------------------------------------------------

function clickClear (): void {
  value = ''

  if (callbackValChanged !== undefined) {
    callbackValChanged('', callbackData)
  }

  inputElement?.focus()

  if (callbackInputCleared !== undefined) {
    callbackInputCleared(callbackData)
  }
}

// -------------------------------------------------------------------

function initInputElement (el: HTMLElement): void {
  if (shouldFocus) {
    el?.focus()
  }
}

// -------------------------------------------------------------------

function setType (el: HTMLInputElement): void {
  el.type = type ?? ''
}
</script>

<!-- ------------------------------------------------------------- -->

<div class={cn('pz-input-wrapper flex grow', className)}>
  <div class="relative w-full">
    <input
      bind:this={inputElement}
      class="w-full rounded-[12px] border border-input px-4
        py-2 focus:border-primary focus:outline-none focus:ring-0"
      class:z-disallow-password-manager-icons={!allowPasswordManagerIcons}
      {...extraAttrs}
      oninput={(e) => {
        valChanged(e)
      }}
      {placeholder}
      bind:value
      use:initInputElement
      use:setType
    />
    {#if showClearButton}
      <div class="absolute bottom-0 right-3 top-0 flex flex-row items-center">
        <button class="pz-input-clear rounded-full bg-primary p-1" onclick={clickClear}>
          <ZIcon class="flex" icon={NuIconClose} />
        </button>
      </div>
    {/if}
  </div>
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.pz-input-clear
  display none

input::placeholder
  color theme('colors.pz_gray.mid')
  font-size var(--pz-font-size-13)
  font-style italic

:global(body:has(.z-disallow-password-manager-icons))
  :global(div[data-lastpass-root])
    display none !important

.pz-input-wrapper
  &:has(.z-disallow-password-manager-icons)
    :global(div[data-lastpass-icon-root])
      display none !important
  :global(.z-icon)
    width var(--pz-px-8)
    height var(--pz-px-8)
    color white
  &:focus-within
    :global(.pz-input-clear)
      display block
</style>
