export function objectWithFlatKeysToDeepObject (obj: Record<string, unknown>): Record<string, unknown> {
  const res: Record<string, unknown> = {}
  for (const key in obj) {
    const path = key.split('.')
    let o = res
    for (let i = 0; i < path.length - 1; i++) {
      o = o[path[i]] = (o[path[i]] ?? {}) as Record<string, unknown>
    }
    o[path[path.length - 1]] = obj[key]
  }
  return res
}

// -------------------------------------------------------------------

export function objectToFlatKeys (obj: Record<string, unknown>): Record<string, unknown> {
  const res: Record<string, unknown> = {}
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const subRes = objectToFlatKeys(obj[key] as Record<string, unknown>)
      for (const subKey in subRes) {
        res[key + '.' + subKey] = subRes[subKey]
      }
    } else {
      res[key] = obj[key]
    }
  }
  return res
}

// -------------------------------------------------------------------

export function objectFindKeysFromObj1MissingInObj2 (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): Record<string, unknown> {
  const res: Record<string, unknown> = {}
  for (const key in obj1) {
    if (typeof obj1[key] === 'object') {
      const subRes = objectFindKeysFromObj1MissingInObj2(
        obj1[key] as Record<string, unknown>,
        obj2[key] as Record<string, unknown>
      )
      for (const subKey in subRes) {
        res[key + '.' + subKey] = subRes[subKey]
      }
    } else if (obj1[key] !== obj2[key]) {
      res[key] = obj1[key]
    }
  }
  return res
}

// -------------------------------------------------------------------

export function flatKeyRemoveLast (key: string): string {
  const parts = key.split('.')
  parts.pop()
  return parts.join('.')
}
