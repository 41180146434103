import { personModuleInit } from '@/modules/contactModule'
import { prayerModuleInit } from '@/modules/prayerModule'

// import '@utils/dxos'
import { type AuthState, useAuth } from './auth'
import { getAppVersion } from './env'
import { type InstanceState, useInstance } from './instance'
import { type MorphSrvClientsState, useMorphSrvClients } from './morphSrvClients'
// import { useSpaceDbs } from './spaceDb'
import { usePwa } from './pwa'
import { type ReadyForActionState, useReadyState } from './readyForAction'
import { type SpaceState, useSpaces } from './space'
// import { useInstanceDb, type InstanceState } from './instanceDb'
import { type UserDbState, useUserDb } from './userDb'

// -------------------------------------------------------------------

export interface UseStateReturn {
  start: () => void
  extendState: (aState: AppState) => void
}

// -------------------------------------------------------------------

const auth = useAuth()
const readystate = useReadyState()
const userDb = useUserDb()
const instance = useInstance()
// const instanceDb = useInstanceDb()
// const spaceDbs = useSpaceDbs()
const spaces = useSpaces()
const morphSrvClient = useMorphSrvClients()
usePwa()

// -------------------------------------------------------------------

// Partial<...> makes the properties optional.
// interface AppState extends Partial<InstanceState> {
export interface AppState
  extends Partial<AuthState>,
  Partial<MorphSrvClientsState>,
  Partial<UserDbState>,
  Partial<InstanceState>,
  Partial<SpaceState>,
  Partial<ReadyForActionState> {
  appVersion: string
}

// -------------------------------------------------------------------

export const appState: AppState = {
  appVersion: getAppVersion()
}

// -------------------------------------------------------------------

auth.extendState(appState)
readystate.extendState(appState)
userDb.extendState(appState)
instance.extendState(appState)
// instanceDb.extendState()
// spaceDbs.extendState()
spaces.extendState(appState)
morphSrvClient.extendState(appState)

// -------------------------------------------------------------------

personModuleInit()
prayerModuleInit()

// -------------------------------------------------------------------

instance.start()
userDb.start()
// instanceDb.start()
// spaceDbs.start()
spaces.start()
morphSrvClient.start()
auth.start() // Must be last!?

// -------------------------------------------------------------------

export function injectState (ky: string, get: any, set: any = null): void {
  Object.defineProperty(appState, ky, {
    get,
    ...(set !== null && { set }),
    enumerable: true,
    configurable: true
  })
}
