<script lang="ts">
import { teleport } from '@utils/teleport.ts'

import { startNewSideMenu } from '../store/sideMenu'

import AppSideMenuHeader from './AppSideMenuHeader.svelte'
import AppSideMenuList from './AppSideMenuList.svelte'
import AppSideMenuListItem from './AppSideMenuListItem.svelte'

// import type { SideMenuOpenState } from '../store/sideMenu'

// -------------------------------------------------------------------

const C = {
  List: AppSideMenuList,
  ListItem: AppSideMenuListItem
}

// -------------------------------------------------------------------

const { onClose, title, children } = $props()

// -------------------------------------------------------------------

const sideMenuStackItem = startNewSideMenu({
  onClose
})

// -------------------------------------------------------------------

const isOpenOrOpening = $derived.by(() => {
  const state = sideMenuStackItem.openState
  return state === 'open' || state === 'opening'
})

// -------------------------------------------------------------------

export function initClose (): void {
  sideMenuStackItem.initClose()
}
</script>

<!-- ------------------------------------------------------------- -->

<div use:teleport={'z-app-side-menu'}>
  <div
    class="z-app-side-menu z-app-side-menu-is-open flex flex-col"
    class:z-app-side-menu-is-open-not-closing={isOpenOrOpening}
  >
    <div class="z-app-side-menu-outer flex grow flex-col p-4">
      <div class="z-app-side-menu-inner flex grow flex-col rounded-3xl bg-app px-11 py-8">
        <AppSideMenuHeader {initClose} {title} />

        <div class="flex grow flex-col">
          {@render children(C)}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.z-app-side-menu
  position absolute
  top 0
  bottom 0
  padding var(--nu-sz-lg)
  width "min(90vw, 400px)" % (100% 100%)
  right "calc(-1 * min(90vw, 400px))" % (100% 100%)
  transition right var(--z-app-side-menu-open-duration) ease-in-out
  z-index 101
  &.z-app-side-menu-is-open-not-closing
    right 0
</style>
