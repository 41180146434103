import type { Component } from 'svelte'

import NuIconA from '@icons/NuIconA.svelte'
import NuIconAlignCenter from '@icons/NuIconAlignCenter.svelte'
import NuIconAlignJustify from '@icons/NuIconAlignJustify.svelte'
import NuIconAlignLeft from '@icons/NuIconAlignLeft.svelte'
import NuIconAlignRight from '@icons/NuIconAlignRight.svelte'
import NuIconBold from '@icons/NuIconBold.svelte'
import NuIconFormatClear from '@icons/NuIconFormatClear.svelte'
// import NuIconH1 from '@icons/NuIconH1.svelte'
import NuIconHorizontalRule from '@icons/NuIconHorizontalRule.svelte'
import NuIconItalic from '@icons/NuIconItalic.svelte'
import NuIconListBullet from '@icons/NuIconListBullet.svelte'
import NuIconListCheck from '@icons/NuIconListCheck.svelte'
import NuIconListNumbered from '@icons/NuIconListNumbered.svelte'
import NuIconMarker from '@icons/NuIconMarker.svelte'
import NuIconStrikethrough from '@icons/NuIconStrikethrough.svelte'

// -------------------------------------------------------------------

/*
TODO I18n
TODO Keyboard shortcuts

TODO Undo redo
TODO Print
TODO Spellcheck
TODO Zoom
TODO Font family
TODO Font size
TODO Link
TODO Add comment\
TODO Insert image
TODO Line/paragraph spacing
TODO Increase/decrease indent
*/

// -------------------------------------------------------------------

type EditorToolbarDef = EditorToolbarDefGroup[]

// -------------------------------------------------------------------

interface EditorToolbarDefGroup {
  advanced?: boolean
  items: EditorToolbarDefItem[]
}

// -------------------------------------------------------------------

interface EditorToolbarDefItem {
  type?: string
  icon: Component
  title: string
  action: (() => void) | ((v: any) => void)
  isActive: string
  advanced?: boolean
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-lines-per-function
export const getEditorToolbarDef = (editor: any): EditorToolbarDef => {
  return [
    {
      items: [
        {
          icon: NuIconListCheck,
          title: 'Checklist',
          action: () => editor.chain().focus().toggleTaskList().run(),
          isActive: 'isTaskList'
        },
        {
          icon: NuIconListBullet,
          title: 'Bullet List',
          action: () => editor.chain().focus().toggleBulletList().run(),
          isActive: 'isBulletList'
        },
        {
          icon: NuIconListNumbered,
          title: 'Ordered List',
          action: () => editor.chain().focus().toggleOrderedList().run(),
          isActive: 'isOrderedList',
          advanced: true
        }
      ]
    },
    // {
    //   advanced: true,
    //   items: [
    //     {
    //       type: 'paragraph',
    //       icon: NuIconAlignLeft,
    //       advanced: true,
    //     },
    //   ]
    // },
    {
      items: [
        {
          icon: NuIconBold,
          title: 'Bold',
          action: () => editor.chain().focus().toggleBold().run(),
          isActive: 'isBold'
        },
        {
          icon: NuIconItalic,
          title: 'Italic',
          action: () => editor.chain().focus().toggleItalic().run(),
          isActive: 'isItalic'
        },
        {
          icon: NuIconStrikethrough,
          title: 'Strike',
          action: () => editor.chain().focus().toggleStrike().run(),
          isActive: 'isStrike',
          advanced: true
        },
        // {
        //   icon: 'code-view',
        //   title: 'Code',
        //   action: () => editor.chain().focus().toggleCode().run(),
        //   isActive: () => editor.isActive('code'),
        // },
        {
          icon: NuIconMarker,
          title: 'Highlight',
          action: () => editor.chain().focus().toggleHighlight({ color: '#faf594' }).run(),
          isActive: 'isHighlight',
          advanced: false
        },
        {
          type: 'color',
          icon: NuIconA,
          title: 'Text color',
          action: (v: any) => editor.chain().focus().setColor(v).run(),
          isActive: 'isTextStyle',
          advanced: true
        },
        {
          type: 'bgcolor',
          icon: NuIconMarker,
          title: 'Highlight color',
          action: (v: any) => editor.chain().focus().toggleHighlight({ color: v }).run(),
          isActive: 'isHighlight',
          advanced: true
        }
      ]
    },
    {
      advanced: true,
      items: [
        {
          icon: NuIconAlignLeft,
          title: 'Align left',
          action: () => editor.chain().focus().setTextAlign('left').run(),
          isActive: 'isAlignLeft',
          advanced: true
        },
        {
          icon: NuIconAlignCenter,
          title: 'Align center',
          action: () => editor.chain().focus().setTextAlign('center').run(),
          isActive: 'isAlignCenter',
          advanced: true
        },
        {
          icon: NuIconAlignRight,
          title: 'Align right',
          action: () => editor.chain().focus().setTextAlign('right').run(),
          isActive: 'isAlignRight',
          advanced: true
        },
        {
          icon: NuIconAlignJustify,
          title: 'Align justify',
          action: () => editor.chain().focus().setTextAlign('justify').run(),
          isActive: 'isAlignJustify',
          advanced: true
        }
      ]
    },
    {
      advanced: true,
      items: [
        {
          icon: NuIconHorizontalRule,
          title: 'Horizontal line',
          action: () => editor.chain().focus().setHorizontalRule().run(),
          isActive: '',
          advanced: true
        }
      ]
    },
    {
      advanced: true,
      items: [
        {
          icon: NuIconFormatClear,
          title: 'Clear formatting',
          action: () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
          isActive: '',
          advanced: true
        }
      ]
    }
    // {
    //   advanced: true,
    //   items: [
    //     // {
    //     //   icon: NuIconH1,
    //     //   title: 'Heading 1',
    //     //   action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    //     //   isActive: () => editor.isActive('heading', { level: 1 }),
    //     // },
    //     // {
    //     //   icon: 'h-2',
    //     //   title: 'Heading 2',
    //     //   action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    //     //   isActive: () => editor.isActive('heading', { level: 2 }),
    //     // },
    //     // {
    //     //   icon: 'h-3',
    //     //   title: 'Heading 3',
    //     //   action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    //     //   isActive: () => editor.isActive('heading', { level: 3 }),
    //     // },
    //     // {
    //     //   icon: 'h-4',
    //     //   title: 'Heading 4',
    //     //   action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
    //     //   isActive: () => editor.isActive('heading', { level: 4 }),
    //     // },
    //     // {
    //     //   icon: 'paragraph',
    //     //   title: 'Paragraph',
    //     //   action: () => editor.chain().focus().setParagraph().run(),
    //     //   isActive: () => editor.isActive('paragraph'),
    //     // },
    //     // {
    //     //   icon: 'code-box-line',
    //     //   title: 'Code Block',
    //     //   action: () => editor.chain().focus().toggleCodeBlock().run(),
    //     //   isActive: () => editor.isActive('codeBlock'),
    //     // },
    //     {
    //       icon: 'double-quotes-l',
    //       title: 'Blockquote',
    //       action: () => editor.chain().focus().toggleBlockquote().run(),
    //       isActive: () => editor.isActive('blockquote'),
    //       advanced: true,
    //     },
    //     // {
    //     //   icon: 'text-wrap',
    //     //   title: 'Hard Break',
    //     //   action: () => editor.chain().focus().setHardBreak().run(),
    //     // },
    //     // {
    //     //   icon: 'arrow-go-back-line',
    //     //   title: 'Undo',
    //     //   action: () => editor.chain().focus().undo().run(),
    //     // },
    //     // {
    //     //   icon: 'arrow-go-forward-line',
    //     //   title: 'Redo',
    //     //   action: () => editor.chain().focus().redo().run(),
    //     // },
    //     // {
    //     //   type: 'divider',
    //     //   advanced: true,
    //     // },
    //   ]
    // }
  ]
}
