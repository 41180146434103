import { ydbInitDB } from '@utils/ydb'
import { type YDB } from '@utils/ydb'

import { appState } from './appState'

// -------------------------------------------------------------------

export interface UserDbState {
  userDb: YDB
}

// -------------------------------------------------------------------

export function useUserDb (): any {
  return {
    start: () => {
      $effect.root(() => {
        $effect(() => {
          // console.log('useUserDb', appState.authenticatedUserUuidB62)
          if (appState.authenticatedUserUuidB62 != null && appState.userDb === undefined) {
            // console.log('useUserDb 222', appState.userDb)
            appState.userDb = ydbInitDB(appState.authenticatedUserUuidB62)
            // console.log('useUserDb 333', appState.userDb)
          }
        })
      })
    },

    extendState () {}
  }
}
