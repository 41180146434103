<script lang="ts">
import { ensuredDefined } from '@utils/error'
import type { YDoc } from '@utils/y'

import { appState } from '../store/appState'

import { itemGetAllByBreed } from '../store/item'
import { type Space } from '../store/space'

import DataTable from '../components/DataTable'
import LogPreview from '../components/LogPreview'
import { type Defs, loadDefs } from '../defs/defs'
import { gotoUrlWithoutChangingHistory, type UIParams } from '../routes'

// -------------------------------------------------------------------

const {
  params
}: {
  params: UIParams
} = $props()

let itemDocs = $state<YDoc[]>([])

// -------------------------------------------------------------------

let defs: Defs | null = $state(null)

// -------------------------------------------------------------------

const columns = $derived.by(() => {
  return (
    defs?.views?.list?.columns.map((columnDef) => {
      return {
        title: defs?.fields?.[columnDef]?.title,
        field: columnDef
      }
    }) ?? []
  )
})

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  void loadDefs(params?.breed).then((loadedDefs) => (defs = loadedDefs))
})

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  const breed: string = params?.breed

  const activeSpace: Space = ensuredDefined(appState.activeSpace, 'appState.activeSpace not initialized')
  void itemGetAllByBreed({ spaceId: activeSpace.id, breed, docs: itemDocs, loadData: 'subscribe' }).then(
    (loadedItemDocs) => (itemDocs = loadedItemDocs)
  )
})

// -------------------------------------------------------------------

const data = $derived.by(() => {
  return (
    itemDocs?.map((itemDoc) => {
      return { ...itemDoc?.item?.ival, __itemId: itemDoc.id }
    }) ?? []
  )
})

// -------------------------------------------------------------------

function onClickRow (dataItem: Record<string, unknown>): void {
  if (typeof dataItem?.__itemId === 'string') {
    // gotoUrlWithoutChangingHistory('/' + params?.breed + '/' + dataItem?.__itemId + '/edit')
    gotoUrlWithoutChangingHistory('/' + params?.breed + '/' + dataItem?.__itemId)
  }
}
</script>

<!-- ------------------------------------------------------------- -->

<LogPreview dat={params?.breed} title="breed" />
<LogPreview dat={itemDocs} title="itemDocs" />
<!-- <LogPreview dat={ defs } title="defs" /> -->

<div>
  <DataTable {columns} {data} {onClickRow} />
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">

</style>
