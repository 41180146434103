let lastMsg = ''
let lastCnt = 0

// eslint-disable-next-line max-statements
export function log (msg: any): void {
  const debugPane = document.getElementById('pz-debug-pane')
  if (debugPane != null) {
    let s = ''
    if (typeof msg === 'string') {
      s = msg
    } else {
      s = JSON.stringify(msg)
    }
    if (lastMsg !== s) {
      debugPane.innerHTML += '<div>' + s + '</div>'
      lastCnt = 0
    } else {
      lastCnt++
      const lastMsgNode = debugPane.childNodes[debugPane.childNodes.length - 1]
      const cntNodes = lastMsgNode.querySelectorAll('.pz-dbg-cnt-node')
      let cntNode = null
      if (cntNodes.length > 0) {
        cntNode = cntNodes[cntNodes.length - 1]
        cntNode.innerHTML = lastCnt
      } else {
        lastMsgNode.innerHTML += '<span class="pz-dbg-cnt-node">' + lastCnt + '</span>'
      }
    }

    lastMsg = s

    console.log('DebugPane LOG', msg)

    if (debugPane.childNodes.length > 100) {
      debugPane.removeChild(debugPane.childNodes[0])
    }

    debugPane.scrollTop = debugPane.scrollHeight
  }
}
