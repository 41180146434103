import type { Component } from 'svelte'
import { t } from 'i18next'

import type { Defs } from '@/defs/defs'
import Item from '@/views/Item.svelte'
import ItemList from '@/views/ItemList.svelte'

import { addHookAddMenuList, addHookDefs, addHookMainMenuList, addHookRouteList } from './hooks'

import NuIconUser from '@icons/NuIconUser.svelte'
import NuIconUserGroup from '@icons/NuIconUserGroup.svelte'

// -------------------------------------------------------------------

export function personModuleInit (): void {
  addHookAddMenuList((addMenuList) => {
    addMenuList.push({
      icon: NuIconUser,
      title: t('Add new contact'),
      slug: '/contact/new/edit'
    })
  })

  addHookMainMenuList((mainMenuList) => {
    mainMenuList.push({
      icon: NuIconUserGroup,
      title: t('Contacts'),
      slug: '/contacts'
    })
  })

  addHookRouteList((routes) => {
    routes.push({
      path: '/contacts',
      params: { breed: 'contact' },
      component: ItemList as unknown as Component
    })
    routes.push({
      path: '/contact/:id/:mode?',
      params: { breed: 'contact' },
      component: Item as unknown as Component
    })
  })

  addHookDefs(async (breed): Promise<Defs | null> => {
    if (breed === 'contact') {
      const { getDef } = await import('@/defs/contact/personDefs')
      return getDef()
    }

    return null
  })
}
