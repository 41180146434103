import { appState, injectState, type UseStateReturn } from './appState'

import {
  morphSrvCheckAddSpace,
  type MorphSrvClients,
  morphSrvCreateClient,
  morphSrvGetSrvIdForSpace
} from '../services/morphSrv'

import { log } from '../utils/debug'

import type { Space } from './space'

// -------------------------------------------------------------------

const morphSrvClients: MorphSrvClients = $state({})

// -------------------------------------------------------------------

export interface MorphSrvClientsState {
  morphSrvClients: MorphSrvClients
}

// -------------------------------------------------------------------

function checkMorphSrvClientExists (id: string): boolean {
  let exists = false

  if (appState.morphSrvClients !== undefined) {
    for (const srv of Object.values(appState.morphSrvClients)) {
      if (srv.id === id) {
        exists = true
        break
      }
    }
  }

  return exists
}

// -------------------------------------------------------------------

function checkAddMorphSrvClient (space: Space): void {
  if (appState.userDb !== undefined && appState.morphSrvClients !== undefined) {
    const morphSrvDef = morphSrvGetSrvIdForSpace(space.id)
    // console.log('checkAddMorphSrvClient 111', space, morphSrvDef)
    log(morphSrvDef)

    if (!checkMorphSrvClientExists(morphSrvDef.id)) {
      appState.morphSrvClients[morphSrvDef.id] = morphSrvCreateClient(morphSrvDef, appState.userDb.db)
    }

    morphSrvCheckAddSpace(appState.morphSrvClients[morphSrvDef.id], space, morphSrvDef)
  }

  // console.log('checkAddMorphSrvClient', appState.morphSrvClients)
}

// -------------------------------------------------------------------

export function useMorphSrvClients (): UseStateReturn {
  return {
    start: () => {
      $effect.root(() => {
        $effect(() => {
          // console.log('useMorphSrvClients 000', appState.userDb)
          if (
            appState.isLoggedInAndIdKnown === true &&
            appState.userDb !== undefined &&
            appState.spaces !== undefined
          ) {
            // console.log('useMorphSrvClients 111', appState.spaces)
            for (const space of Object.values(appState.spaces)) {
              // console.log('useMorphSrvClients 222', space)
              checkAddMorphSrvClient(space)
            }
          }
        })
      })
    },

    extendState () {
      injectState('morphSrvClients', () => morphSrvClients)
    }
  }
}
