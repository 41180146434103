import { cloneDeep } from '@/utils/clone'

// import { objectToFlatKeys } from '@/utils/object'
import type { UuidB62 } from '@utils/id'
import {
  yDocApplyUpdate,
  yDocCreate,
  yDocGetKeyValue,
  yDocOnUpdate,
  yDocSetKeyValue,
  yDocSyncJsObjectToYDoc,
  yDocToJsObject
} from '@utils/y'

// -------------------------------------------------------------------

const ERROR = 'ERROR'
const SPACE: UuidB62 = '00000000000000123' as UuidB62

// -------------------------------------------------------------------

type TestFunction = (tst: string) => Promise<string>

// -------------------------------------------------------------------

const tests: Record<string, TestFunction> = {
  yTestSetKeyValue,
  yTestApplyReceivedUpdate,
  yTestSetDeepObject,
  yTestSetObjectOneDeepValue,
  yTestSetObjectOverExistingObject
}

// -------------------------------------------------------------------

export async function yRunTest (tst: string): Promise<string> {
  for (const [name, test] of Object.entries(tests)) {
    if (name === tst) {
      return await test(tst)
    }
  }
  return ''
}

// -------------------------------------------------------------------

export async function yTestSetKeyValue (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const doc = yDocCreate({ spaceId: SPACE })

    yDocOnUpdate(doc, (update) => {
      resolve(update.length > 0 ? 'yTestSetKeyValue' : ERROR)
    })

    yDocSetKeyValue(doc, 'test', 'test_value')
  })
}

// -------------------------------------------------------------------

export async function yTestApplyReceivedUpdate (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const ky = 'test'
    const val = 'test_value'
    const doc = yDocCreate({ spaceId: SPACE })

    yDocOnUpdate(doc, (update) => {
      const doc2 = yDocCreate({ spaceId: SPACE })
      yDocApplyUpdate(doc2, update)
      resolve(yDocGetKeyValue(doc2, ky) === val ? 'yTestApplyReceivedUpdate' : ERROR)
    })

    yDocSetKeyValue(doc, ky, val)
  })
}

// -------------------------------------------------------------------

export async function yTestSetDeepObject (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const deepObj = {
      sub1: {
        sub2a: {
          sub3a: 'test',
          sub3b: 'test2'
        },
        sub2b: true
      }
    }

    const doc = yDocCreate({ spaceId: SPACE })

    yDocSyncJsObjectToYDoc(doc, deepObj)

    const objectRetrieved = yDocToJsObject(doc)

    resolve(JSON.stringify(objectRetrieved) === JSON.stringify(deepObj) ? 'yTestSetDeepObject' : ERROR)
  })
}

// -------------------------------------------------------------------

export async function yTestSetObjectOneDeepValue (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const val = 'test_value'
    const deepObj = {
      sub1: {
        sub2a: {
          sub3a: 'test',
          sub3b: 'test2'
        },
        sub2b: true
      }
    }

    const doc = yDocCreate({ spaceId: SPACE })

    yDocSyncJsObjectToYDoc(doc, deepObj)

    yDocOnUpdate(doc, (update) => {
      const objectRetrievedJson = JSON.stringify(yDocToJsObject(doc))
      const deepObjChanged: any = cloneDeep(deepObj)
      deepObjChanged.sub1.sub2a.sub3a = val
      // console.log('objectRetrievedJson', JSON.parse(objectRetrievedJson))
      // console.log('deepObjChanged', deepObjChanged)
      resolve(objectRetrievedJson === JSON.stringify(deepObjChanged) ? 'yTestSetObjectOneDeepValue' : ERROR)
    })

    yDocSetKeyValue(doc, 'sub1.sub2a.sub3a', val)
  })
}

// -------------------------------------------------------------------

export async function yTestSetObjectOverExistingObject (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const deepObj = {
      sub1: {
        sub2a: {
          sub3a: 'test',
          sub3b: 'test2'
        },
        sub2b: true
      },
      sub1a: 'hey'
    }
    const deepObj2 = {
      sub1: {
        sub2a: {
          sub3b: 'test2b'
        },
        sub2b: 'test2'
      },
      sub1b: {
        sub1b_2a: {
          sub1b_3a: 'test',
          sub1b_3b: 'test2'
        },
        sub1b_2b: false
      }
    }

    const doc = yDocCreate({ spaceId: SPACE })

    yDocSyncJsObjectToYDoc(doc, deepObj)

    yDocOnUpdate(doc, (update) => {
      const objectRetrievedJson = JSON.stringify(yDocToJsObject(doc))
      // console.log('objectRetrievedJson', JSON.parse(objectRetrievedJson))
      // console.log('deepObj2', deepObj2)
      // console.log('objectToFlatKeys', objectToFlatKeys(deepObj2))
      resolve(objectRetrievedJson === JSON.stringify(deepObj2) ? 'yTestSetObjectOverExistingObject' : ERROR)
    })

    yDocSyncJsObjectToYDoc(doc, deepObj2)
  })
}
