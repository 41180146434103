<script lang="ts">
import { t } from 'i18next'

import { loginWithEmailAndPassword, signup } from '../store/auth'

import imgUrl from '../../apple-touch-icon.png'
import ZButton from '../components/ZButton.svelte'
import ZInput from '../components/ZInput'
import { gotoUrl } from '../routes'

// -------------------------------------------------------------------

const { params } = $props()

// -------------------------------------------------------------------

const isLogin: boolean = $derived(params?.isLogin)
const isRegister: boolean = $derived(params?.isRegister)

// -------------------------------------------------------------------

let email = $state('weg@weg.com')
let password = $state('weg12345!')
let name = $state('weg123')

let errorMsg = $state('')
let loginErrMsg = $state('')

// -------------------------------------------------------------------

const links = {
  login: { href: '/login', preTxt: t('Already a user?'), txt: t('Click here to login.') },
  register: { href: '/register', preTxt: t('No account yet?'), txt: t('Click here to register.') }
}

// -------------------------------------------------------------------

function resetErrors (): void {
  errorMsg = ''
  loginErrMsg = ''
}

// -------------------------------------------------------------------

async function submitLogin (): Promise<void> {
  if ((await loginWithEmailAndPassword(email, password)).ok === true) {
    gotoUrl('/')
  }
}

// -------------------------------------------------------------------

async function submitRegister (): Promise<void> {
  const res = await signup(email, password, name ?? email)
  if (typeof res?.errorMsg === 'string') {
    errorMsg = res.errorMsg
  }
  if (typeof res?.loginErrMsg === 'string') {
    loginErrMsg = res.loginErrMsg
  }
  if (typeof res?.errorMsg !== 'string') {
    gotoUrl('/')
  }
}

// -------------------------------------------------------------------

async function submit (event: Event): void {
  event.preventDefault()

  resetErrors()

  if (isLogin) {
    await submitLogin()
  }

  if (isRegister) {
    await submitRegister()
  }
}
</script>

<!-- ------------------------------------------------------------- -->

{#snippet lnk({ href, txt, preTxt })}
  {preTxt}
  <a class="underline decoration-pz_gray-light underline-offset-[3px] hover:decoration-pz_gray-mid" {href}>
    {txt}
  </a>
{/snippet}

<!-- ------------------------------------------------------------- -->

<!-- <p v-if="needsEmailVerification">
  Your email is not yet verified. Please check your mailbox and
  follow the verification link to finish registration.
</p> -->

<!-- v-else -->

<div class="flex w-full grow items-center justify-center bg-primary">
  <form class=" rounded-3xl bg-white p-8" onsubmit={submit}>
    <!-- <div class="relative mb-10 flex w-full flex-col items-center">
      <div class="absolute top-[-74px] overflow-hidden rounded-3xl border-[4px] border-primary bg-white p-2">
        <img class="h-16 w-16 translate-x-[2px]" alt="logo" src={ imgUrl } />
      </div>
    </div> -->

    <div class=" mb-10 flex w-full flex-col items-center">
      <img class="h-16 w-16 translate-x-[2px]" alt="logo" src={imgUrl} />
    </div>

    {#if errorMsg}
      <div class="text-error">
        {errorMsg}
        {#if loginErrMsg}
          <a href="/login">
            {loginErrMsg}
          </a>
        {/if}
      </div>
    {/if}

    <ZInput
      class="input mb-2"
      allowPasswordManagerIcons={true}
      placeholder={t('Email')}
      type="email"
      bind:value={email}
    />
    {#if isRegister}
      <ZInput class="input mb-2" allowPasswordManagerIcons={true} placeholder={t('Name')} bind:value={name} />
    {/if}
    <ZInput
      class="input mb-2"
      allowPasswordManagerIcons={true}
      placeholder={t('Password')}
      type="password"
      bind:value={password}
    />

    <ZButton class="btn-submit mt-2 w-full" type="submit">
      {isLogin ? t('Login') : t('Register')}
    </ZButton>

    <br /><br />

    {#if isRegister}
      {@render lnk(links.login)}
    {/if}

    {#if isLogin}
      {@render lnk(links.register)}
    {/if}
  </form>
</div>
