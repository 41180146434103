import * as Y from 'yjs'

import type { UuidB62 } from '@utils/id'
import { newUuidB62 } from '@utils/id'
import { objectFindKeysFromObj1MissingInObj2, objectToFlatKeys, objectWithFlatKeysToDeepObject } from '@utils/object'

// -------------------------------------------------------------------

export interface YDoc {
  id: UuidB62
  spaceId: UuidB62
  breed: string
  yd: Record<string, Y.Doc> // Individual docs, for the main doc (for the ym Map) and the(flattened) keys starting with # (are not in ym.)
  ym?: Y.Map<any>
  item?: any
}

// -------------------------------------------------------------------

export function yDocCreate ({
  spaceId,
  breed = '',
  itemId = null,
  item = null
}: {
  spaceId: UuidB62
  breed?: string
  itemId?: UuidB62 | null
  item?: unknown
}): YDoc {
  const _id = itemId ?? newUuidB62()

  const doc: YDoc = {
    spaceId,
    id: _id,
    breed,
    yd: {},
    ...(item != null && { item })
  }

  doc.yd['#'] = new Y.Doc() // This is the main doc, containing the Map.
  // TODO Disable garbage collection for yd['#'], so the full history will be preserved and old content could be restored. See doc.gc: https://docs.yjs.dev/api/y.doc

  doc.ym = doc.yd['#'].getMap('m')

  // doc.yd['#'].on('update', update => {
  //   console.log('yDocCreate update', doc, update)
  // })

  return doc
}

// -------------------------------------------------------------------

export function yDocOnUpdate (doc: YDoc, onUpdate: (update: Uint8Array, origin: unknown) => void): void {
  // TODO unsub?
  doc.yd['#'].on('update', (update: Uint8Array, origin: any) => {
    // console.trace('yDocOnUpdate', doc, update, origin)
    onUpdate(update, origin)
  })
}

// -------------------------------------------------------------------

export function yDocSetKeyValue (doc: YDoc, ky: string, val: unknown): void {
  if (doc.ym !== undefined) {
    doc.ym.set(ky, val)
  }
}

// -------------------------------------------------------------------

export function yDocGetKeyValue (doc: YDoc, ky: string): unknown {
  if (doc.ym !== undefined) {
    return doc.ym.get(ky)
  }
  return null
}

// -------------------------------------------------------------------

export function yDocGetSubDoc (doc: YDoc, ky: string): Y.Doc | null {
  if (ky.startsWith('#')) {
    if (doc.yd[ky] === undefined) {
      doc.yd[ky] = new Y.Doc()
    }
    return doc.yd[ky]
  }
  return null
}

// -------------------------------------------------------------------

export function yDocApplyUpdate (doc: YDoc, update: Uint8Array, origin: unknown = null): void {
  Y.applyUpdate(doc.yd['#'], update, 'applyfromdb')
  // console.log('yDocApplyUpdate', yDocGetKeyValue(doc, 'firstName'))
  // console.log('yDocApplyUpdate', yDocToJsObject(doc))
}

// -------------------------------------------------------------------

export function yDocApplySubDocUpdate ({
  doc,
  update,
  origin = null,
  ky
}: {
  doc: YDoc
  update: Uint8Array
  origin: unknown
  ky: string
}): void {
  if (doc.yd[ky] === undefined) {
    yDocGetSubDoc(doc, ky)
  }

  if (doc.yd[ky] !== undefined) {
    Y.applyUpdate(doc.yd[ky], update, origin)
    // console.log('yDocApplySubDocUpdate', ky)
  }
}

// -------------------------------------------------------------------

export function yDocSyncJsObjectToYDoc (doc: YDoc, jsObject: Record<string, unknown>): void {
  // See: https://discuss.yjs.dev/t/best-way-to-store-deep-json-objects-js-object-or-y-map/2223/10
  // function typeRequiresRecursive (value: any) {
  //   return typeof value === 'object' || Array.isArray(value)
  // }

  // // Recursively traverse the object and apply updates to the Yjs object
  // function apply (yMap: Y.Map<any>, jsObject: any, path: string) {
  //   if (typeof jsObject === 'object') {
  //     for (const key in jsObject) {
  //       const yValue = yMap.get(key)
  //       const pth = path ? path + '.' + key : key
  //       if (typeRequiresRecursive(jsObject[key])) {
  //         apply(yMap, jsObject[key], pth)
  //       } else if (yValue !== jsObject[key]) {
  //         yMap.set(pth, jsObject[key])
  //       }
  //     }

  //     // TODO Remove keys that are not in the jsObject
  //     // for (const key of yMap.keys()) {
  //     //   if (!(key in jsObject)) {
  //     //     yMap.delete(key)
  //     //   }
  //     // }
  //   // } else if (Array.isArray(jsObject)) {
  //   //   yObject.delete(0, yObject.length)
  //   //   for (let i = 0; i < jsObject.length; i++) {
  //   //     apply(yObject.get(i), typeRequiresRecursive(jsObject[i]) ? apply(jsObject[i]) : jsObject[i])
  //   //   }
  //   }
  // }

  doc.yd['#'].transact((tr) => {
    if (doc.ym !== undefined) {
      // apply(doc.ym, jsObject, '')
      const flatJsObj = objectToFlatKeys(jsObject)
      const flatYmObj = objectToFlatKeys(doc.ym.toJSON())
      const missingKeys = objectFindKeysFromObj1MissingInObj2(flatYmObj, flatJsObj)
      for (const key in missingKeys) {
        doc.ym.delete(key)
      }
      for (const key in jsObject) {
        doc.ym.set(key, jsObject[key])
      }
    }
  })
}

// -------------------------------------------------------------------

export function yDocToJsObject (doc: YDoc): Record<string, unknown> {
  return objectWithFlatKeysToDeepObject(doc.ym?.toJSON() ?? {})
}

// -------------------------------------------------------------------

// const doc1 = new Y.Doc()
// const doc2 = new Y.Doc()

// doc1.on('update', update => {
//   console.log('doc1 update', update)
//   // console.log('doc1 update 111', update.toString())
//   const update64 = bytesToBase64(update)
//   console.log('doc1 update 111', update64, update64.length)
//   // const compressed = LZString.compressToUTF16(update)
//   // console.log('doc1 update 222', compressed)
//   // Y.applyUpdate(doc2, update)
//   Y.applyUpdate(doc2, base64ToBytes(update64))
// })

// doc2.on('update', update => {
//   Y.applyUpdate(doc1, update)
// })

// const ytext1 = doc1.getText('tp')
// const ytext2 = doc2.getText('tp')

// ytext1.insert(0, '#abc#')
// ytext1.insert(2, '=def=')

// console.log('111', ytext2.toString())
