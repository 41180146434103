import { cacheExchange, Client, fetchExchange, subscriptionExchange } from '@urql/core'
import { offlineExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import { type Client as WsClient, createClient as createWSClient, type SubscribePayload } from 'graphql-ws'

import { getNhostClient } from './nhost'

// -------------------------------------------------------------------

export interface QueryOpts {
  callback?: (result: any) => void
  networkOnly?: boolean
}

// -------------------------------------------------------------------

let client: any

let cache: any = cacheExchange

const useNormalizedCache = false

// -------------------------------------------------------------------

if (useNormalizedCache) {
  const storage = makeDefaultStorage({
    idbName: 'graphcache-v3', // The name of the IndexedDB database
    maxAge: 7 // The maximum age of the persisted data in days
  })

  cache = offlineExchange({
    // schema,
    storage
    // updates: {
    //   /* ... */
    // },
    // optimistic: {
    //   /* ... */
    // },
  })
}

// -------------------------------------------------------------------

function newWsClient (): WsClient {
  const nhost = getNhostClient()

  const wsClient = createWSClient({
    url: nhost.graphql.wsUrl,
    keepAlive: 30000,
    connectionParams: () => {
      const token = nhost.auth.getAccessToken()
      return {
        headers: { authorization: token !== undefined ? `Bearer ${token}` : '' }
      }
    }
  })

  return wsClient
}

// -------------------------------------------------------------------

export function graphqlNewClient (): Client {
  const nhost = getNhostClient()
  const wsClient = newWsClient()

  client = new Client({
    url: nhost.graphql.httpUrl,
    fetchOptions: () => {
      const token = nhost.auth.getAccessToken()
      return {
        headers: { authorization: token !== undefined ? `Bearer ${token}` : '' }
      }
    },
    exchanges: [
      cache,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription (request: any) {
          const input: SubscribePayload = { ...request, query: request.query ?? '' }
          return {
            subscribe (sink: any) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              const unsubscribe = wsClient.subscribe(input, sink)
              return { unsubscribe }
            }
          }
        }
      })
    ]
  })

  return client
}

// -------------------------------------------------------------------

interface QOpts {
  requestPolicy?: string
}

function getQOpts (opts: QueryOpts): QOpts {
  const qOpts: QOpts = {}
  if (opts.networkOnly === true) {
    qOpts.requestPolicy = 'network-only'
  }
  return qOpts
}

// -------------------------------------------------------------------

export function graphqlQuery (queryString: any, variables: any, opts: QueryOpts = {}): void {
  if (client === undefined) {
    client = graphqlNewClient()
  }

  client
    .query(queryString, variables, getQOpts(opts))
    .toPromise()
    .then(opts.callback ?? (() => {}))
}

// -------------------------------------------------------------------

export function graphqlSubscriptionQuery (queryString: any, variables: any, opts: QueryOpts = {}): void {
  if (client === undefined) {
    client = graphqlNewClient()
  }

  client
    .subscription(
      // TODO unsubscribe
      queryString,
      variables
    )
    .subscribe(opts.callback ?? (() => {}))
}

// -------------------------------------------------------------------

export function graphqlMutationQuery (queryString: any, variables: any, opts: QueryOpts = {}): void {
  if (client === undefined) {
    client = graphqlNewClient()
  }

  client
    .mutation(queryString, variables, getQOpts(opts))
    .toPromise()
    .then(opts.callback ?? (() => {}))

  // nhost.auth.refreshSession() // TODO Is this needed?
}
