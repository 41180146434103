import { type DbMorph, dexieAddMorph, dexieDestroyDb, dexieGetAllMorphs, dexieInitDb } from '@utils/dexie'
import { newUuidB62 } from '@utils/id'

// -------------------------------------------------------------------

const ERROR = 'ERROR'

// -------------------------------------------------------------------

type TestFunction = (tst: string) => Promise<string>

// -------------------------------------------------------------------

const tests: Record<string, TestFunction> = {
  dexieTestInitDb,
  dexieTestRunQuery
}

// -------------------------------------------------------------------

export async function dexieRunTest (tst: string): Promise<string> {
  for (const [name, test] of Object.entries(tests)) {
    if (name === tst) {
      return await test(tst)
    }
  }
  return ''
}

// -------------------------------------------------------------------

export async function dexieTestInitDb (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const db = dexieInitDb('weg')

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    resolve(db.db !== undefined ? 'dexieTestInitDb' : ERROR)
  })
}

// -------------------------------------------------------------------

function getTestDat (): DbMorph {
  const id = newUuidB62()
  const itemId = newUuidB62()
  const instanceId = newUuidB62()
  const spaceId = newUuidB62()
  const breed = 'test'
  const ymut = new Uint8Array([1, 2, 3])

  const dat: DbMorph = {
    id,
    itemId,
    instanceId,
    spaceId,
    breed,
    instanceClock: 1,
    ymut
  }

  return dat
}

// -------------------------------------------------------------------

export async function dexieTestRunQuery (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    ;(async () => {
      await dexieDestroyDb('weg')

      const db = dexieInitDb('weg')

      const morphs = await dexieGetAllMorphs(db)

      if (morphs.length === 0) {
        const dat = getTestDat()

        const insertedId = await dexieAddMorph(db, dat)

        if (insertedId === dat.id) {
          const morphs2 = await dexieGetAllMorphs(db)
          if (morphs2.length === 1 && morphs2[0].id === dat.id) {
            resolve('dexieTestRunQuery')
          }
        }
      }
    })().catch(reject)
  })
}
