// -------------------------------------------------------------------
// USER
// -------------------------------------------------------------------

export const Q_INSERT_USER_ACCOUNT = /* GraphQL */ `
  mutation Q_INSERT_USER_ACCOUNT($user: users_insert_input!) {
    insertUser(object: $user) {
      id
    }
  }
`

// -------------------------------------------------------------------

export const Q_DESTROY_USER_ACCOUNT = /* GraphQL */ `
  mutation Q_DESTROY_USER_ACCOUNT($userId: uuid!) {
    deleteUsers(where: { id: { _eq: $userId } }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_GET_USER_BY_EMAIL = /* GraphQL */ `
  query Q_GET_USER_BY_EMAIL($email: citext!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`

// -------------------------------------------------------------------

export const Q_UPDATE_USER_DISPLAYNAME_AND_METADATA = /* GraphQL */ `
  mutation Q_UPDATE_USER_DISPLAYNAME_AND_METADATA($id: uuid!, $displayName: String!, $metadata: jsonb) {
    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName, metadata: $metadata }) {
      id
      displayName
      metadata
    }
  }
`

// -------------------------------------------------------------------

export const Q_SUBSCRIBE_USER_DATA = /* GraphQL */ `
  subscription SubscribeAuthenticatedUserData($id: uuid!) {
    user(id: $id) {
      id
      email
      displayName
      metadata
      avatarUrl
    }
  }
`

// -------------------------------------------------------------------
// TRIBE
// -------------------------------------------------------------------

export const Q_CREATE_TRIBE_FOR_USER = /* GraphQL */ `
  mutation Q_CREATE_TRIBE_FOR_USER($tribeId: uuid!, $name: String!, $userId: uuid!) {
    insert_tribes(objects: { id: $tribeId, name: $name, c_user_tribe: { data: { user_id: $userId, rights: "a" } } }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_CREATE_C_USER_TRIBE = /* GraphQL */ `
  mutation Q_CREATE_C_USER_TRIBE($tribeId: uuid!, $userId: uuid!, $rights: bpchar!) {
    insert_c_user_tribe(objects: { tribe_id: $tribeId, user_id: $userId, rights: $rights }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_DELETE_MULTIPLE_TRIBES = /* GraphQL */ `
  mutation Q_DELETE_MULTIPLE_TRIBES($tribeIds: [uuid!]!) {
    delete_tribes(where: { id: { _in: $tribeIds } }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_DELETE_C_USER_TRIBE = /* GraphQL */ `
  mutation Q_DELETE_C_USER_TRIBE($userId: uuid!) {
    delete_c_user_tribe(where: { user_id: { _eq: $userId } }) {
      affected_rows
      returning {
        tribe_id
      }
    }
  }
`

// -------------------------------------------------------------------

export const Q_GET_TRIBE_AND_USERS = /* GraphQL */ `
  query Q_GET_TRIBE_AND_USERS($tribeId: uuid!) {
    tribes(where: { id: { _eq: $tribeId } }) {
      id
      name
      c_user_tribe {
        user_id
        rights
      }
    }
  }
`

// -------------------------------------------------------------------
// SPACE
// -------------------------------------------------------------------

export const Q_CREATE_SPACE_FOR_USER = /* GraphQL */ `
  mutation Q_CREATE_SPACE_FOR_USER($spaceId: uuid!, $name: String!, $userId: uuid!) {
    insert_spaces(objects: { id: $spaceId, name: $name, c_user_space: { data: { user_id: $userId, rights: "a" } } }) {
      affected_rows
      returning {
        id
        name
        c_user_space {
          user_id
          rights
        }
      }
    }
  }
`

// -------------------------------------------------------------------

export const Q_CREATE_C_USER_SPACE = /* GraphQL */ `
  mutation Q_CREATE_C_USER_SPACE($spaceId: uuid!, $userId: uuid!, $rights: bpchar!) {
    insert_c_user_space(objects: { space_id: $spaceId, user_id: $userId, rights: $rights }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_DELETE_C_USER_SPACE = /* GraphQL */ `
  mutation Q_DELETE_C_USER_SPACE($userId: uuid!) {
    delete_c_user_space(where: { user_id: { _eq: $userId } }) {
      affected_rows
      returning {
        space_id
      }
    }
  }
`

// -------------------------------------------------------------------

export const Q_DELETE_MULTIPLE_SPACES = /* GraphQL */ `
  mutation Q_DELETE_MULTIPLE_SPACES($spaceIds: [uuid!]!) {
    delete_spaces(where: { id: { _in: $spaceIds } }) {
      affected_rows
    }
  }
`

// -------------------------------------------------------------------

export const Q_GET_SPACE_AND_USERS = /* GraphQL */ `
  query Q_GET_SPACE_AND_USERS($spaceId: uuid!) {
    spaces(where: { id: { _eq: $spaceId } }) {
      id
      name
      c_user_space {
        user_id
        rights
      }
    }
  }
`

// -------------------------------------------------------------------

export const Q_GET_ALL_USER_SPACES = /* GraphQL */ `
  query Q_GET_ALL_USER_SPACES($userId: uuid!) {
    spaces(where: { c_user_space: { user_id: { _eq: $userId } } }) {
      id
      name
      c_user_space {
        user_id
        rights
      }
    }
  }
`
