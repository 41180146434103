<script lang="ts">
// -------------------------------------------------------------------
// Consider using Shadcn DataTable once it supports Svelte 5: https://www.shadcn-svelte.com/docs/components/data-table
// Or directly using Svelte Headless Table once that supports Svelte 5: https://svelte-headless-table.bryanmylee.com/
// -------------------------------------------------------------------

const { columns, data, onClickRow } = $props()
</script>

<!-- ------------------------------------------------------------- -->

<table class="table-auto">
  <thead>
    <tr>
      {#each columns as column (column.field)}
        <th>{column.title}</th>
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each data as dataItem, index (index)}
      <tr onclick={() => onClickRow(dataItem)}>
        {#each columns as column (index + '_' + column.field)}
          <td>{dataItem[column.field]}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
