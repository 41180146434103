import basex from 'base-x'
import { UUID, uuidv7 } from 'uuidv7'

import type { Branded } from './brandedType'

const BASE62_CHARACTERS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
let bs62: basex.BaseConverter

// -------------------------------------------------------------------

export type UuidV7 = Branded<string, 'UuidV7'>
export type UuidB62 = Branded<string, 'UuidB62'>

// -------------------------------------------------------------------

export const newUuidV7 = (): UuidV7 => {
  return uuidv7() as unknown as UuidV7
}

// -------------------------------------------------------------------

export const newUuidB62 = (): UuidB62 => {
  return uuidV7ToUuidB62(newUuidV7())
}

// -------------------------------------------------------------------

export const newMiniId = (): string => {
  const u = newUuidB62()
  return u.substring(u.length - 4)
}

// -------------------------------------------------------------------

function uuidV7ToUint8Array (u7: UuidV7): Uint8Array {
  return UUID.parse(u7).bytes
}

// -------------------------------------------------------------------

export function uuidV7ToUuidB62 (u7: UuidV7): UuidB62 {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (bs62 === undefined) {
    bs62 = basex(BASE62_CHARACTERS)
  }

  try {
    const u62 = bs62.encode(uuidV7ToUint8Array(u7)) as unknown as UuidB62
    // const u7b = bs62.decode(u62) as unknown as Uint8Array
    // console.log('u7', u7, u62, u7b, isEqualArray(u7, u7b))
    return u62
  } catch (e) {
    console.error('uuidV7ToUuidB62 ERR', e)
    throw e
  }
}

// -------------------------------------------------------------------

export function uuidB62ToUuidV7 (u62: UuidB62): UuidV7 {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (bs62 === undefined) {
    bs62 = basex(BASE62_CHARACTERS)
  }

  try {
    const u7b = bs62.decode(u62) as unknown as Uint8Array
    return UUID.ofInner(u7b).toString() as unknown as UuidV7
  } catch (e) {
    console.error('uuidB62ToUuidV7 ERR', e)
    throw e
  }
}

// -------------------------------------------------------------------

// function isEqualArray (a: any, b: any) {
//   if (a.length !== b.length) return false
//   for (let i = 0; i < a.length; i++) {
//     if (a[i] !== b[i]) {
//       return false
//     }
//   }
//   return true
// }
