<script lang="ts">
import type { Component } from 'svelte'
import { t } from 'i18next'

import type { Defs, DefsField, DefsUI, DefsUIArray, DefsUIGutter, DefsUIRecord } from '@/defs/defs'
import type { YDoc } from '@/utils/y'

import { newMiniId } from '@utils/id'
import { flatKeyRemoveLast } from '@utils/object'

import { itemProcessChange } from '../store/item'

import LogPreview from '../components/LogPreview'

import UiDocNode from './UiDocNode'
import UiInputNode from './UiInputNode'
import ZButton from './ZButton.svelte'

import ZIcon from './ZIcon.svelte'
import NuIconPencil from './icons/NuIconPencil.svelte'

// -------------------------------------------------------------------

interface MultiDataVal {
  mval: unknown
  order?: string // TODO Add Multi fields ordering.
  del?: boolean
}

// -------------------------------------------------------------------

const {
  itemDoc,
  defs,
  ui,
  mode,
  clickActionEdit
}: {
  itemDoc: YDoc
  defs: Defs
  ui: DefsUI
  mode: string
  clickActionEdit: () => void
} = $props()

let lastAddedMultiMiniId: string | undefined = $state()

// -------------------------------------------------------------------

function defsGetField (field: string): DefsField {
  return defs?.fields?.[field]
}

// -------------------------------------------------------------------

function filterOutDeleted (arr: any[]): any[] {
  return arr.filter((v) => v[1]?.del !== true)
}

// -------------------------------------------------------------------

function getMultiDataEntries (ui2: string): Array<Record<string, unknown>> {
  const v: MultiDataVal = {
    mval: ''
  }

  return filterOutDeleted(
    Object.entries((itemDoc?.item?.ival?.[ui2] as Record<string, unknown>) ?? { [newMiniId()]: v })
  )
}

// -------------------------------------------------------------------

function addToMulti (ui2: string): void {
  lastAddedMultiMiniId = newMiniId()
  const v: MultiDataVal = {
    mval: ''
  }
  itemProcessChange(itemDoc, { [ui2 + '.' + lastAddedMultiMiniId]: v })
}

// -------------------------------------------------------------------

function multiInputWasCleared (ky: string): void {
  const kyParts = ky.split('.')
  if (
    filterOutDeleted(Object.entries((itemDoc?.item?.ival?.[kyParts?.[0]] as Record<string, unknown>) ?? {})).length > 1
  ) {
    const v: MultiDataVal = {
      mval: '',
      del: true
    }

    itemProcessChange(itemDoc, { [flatKeyRemoveLast(ky)]: v })
  }
}

// -------------------------------------------------------------------

function shouldFocusMaybe (id: string): boolean {
  return id === lastAddedMultiMiniId
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
function getTextJoin (ui2: unknown): string {
  if (!Array.isArray(ui2)) {
    return ''
  }

  const first = itemDoc?.item?.ival?.[ui2?.[0]]
  const second = itemDoc?.item?.ival?.[ui2?.[1]]

  if (first === undefined && second === undefined) {
    return ''
  }
  if (first === undefined) {
    return second
  }
  if (second === undefined) {
    return first
  }

  return first + ' ' + second
}

// -------------------------------------------------------------------

function clickAction (ui2: string): void {
  if (ui2 === 'edit') {
    clickActionEdit()
  }
}

// -------------------------------------------------------------------

function getActionButtonCfg (ui2: string): { button: string, icon: string, component: Component } | null {
  if (ui2 === 'edit') {
    return {
      button: 'pz-action-edit bg-foreground hover:scale-110',
      icon: 'text-white hover:text-white',
      component: NuIconPencil
    }
  }

  return null
}
</script>

<!-- ------------------------------------------------------------- -->

<!-- <LogPreview dat={ itemDoc } title="itemDoc" />
<LogPreview dat={ defs } title="defs" />
<LogPreview dat={ ui } title="ui" />
<LogPreview dat={ mode } title="mode" /> -->

<!-- ------------------------------------------------------------- -->

{#snippet arrayNode(ui2: DefsUIArray)}
  {#each ui2 as node, index (index)}
    <svelte:self {defs} {itemDoc} {mode} ui={node} />
  {/each}
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet panelNode(ui2: DefsUIRecord)}
  <div class="flex flex-row">
    <div class="flex grow flex-row mobile:flex-col">
      <div class="flex grow flex-col rounded-l-3xl bg-white px-7 py-6 mobile:rounded-t-3xl mobile:rounded-bl-none">
        <svelte:self {defs} {itemDoc} {mode} ui={ui2.ui} />
      </div>

      <div
        class="flex min-w-[180px] rounded-r-3xl bg-pz_gray-bright px-7 py-6 mobile:rounded-b-3xl mobile:rounded-tr-none"
      >
        ...
      </div>
    </div>

    <div>
      {#if ui2.gutter}
        {@render gutterActions(ui2.gutter)}
      {/if}
    </div>
  </div>
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet sectionNode(ui2: DefsUIRecord)}
  <div class="pz-section-h mb-3 flex w-full flex-row items-center gap-2">
    {#if ui2.icon}
      <ZIcon class="size-4" icon={ui2.icon} />
    {/if}
    <h2>{ui2.title}</h2>
  </div>

  <svelte:self {defs} {itemDoc} {mode} ui={ui2.ui} />
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet rowNode(ui2: DefsUIRecord)}
  <div class="flex w-full flex-row items-center gap-2">
    <svelte:self {defs} {itemDoc} {mode} ui={ui2.ui} />
  </div>
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet joinNode(ui2: DefsUIRecord)}
  {@const txt = getTextJoin(ui2.join)}
  {@render textNode(txt, ui2.cssClass)}
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet multiDataNode(ui2: string)}
  {@const field = defsGetField(ui2)}
  {#if field}
    {#each getMultiDataEntries(ui2) as multiDataEntry, index (multiDataEntry[0])}
      <div class="mb-2 flex w-full flex-row items-center gap-4 mobile:gap-2">
        <UiInputNode
          callbackInputCleared={multiInputWasCleared}
          {defs}
          {field}
          {itemDoc}
          ky={ui2 + '.' + (multiDataEntry[0] as string) + '.mval'}
          shouldFocus={shouldFocusMaybe(multiDataEntry[0] as string)}
        />
        {#if index === getMultiDataEntries(ui2).length - 1}
          <ZButton
            class="basis-4/12 truncate
              bg-pz_gray-light font-normal text-pz_gray-midlight hover:bg-pz_gray-midlight hover:text-pz_gray-mid"
            onclick={() => {
              addToMulti(ui2)
            }}
          >
            {t('Add another ') + field?.title.toLowerCase()}
          </ZButton>
        {/if}
      </div>
    {/each}
  {/if}
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet stringNode(ui2: string | Record<string, unknown>)}
  {@const ky = typeof ui2 === 'string' ? ui2 : (ui2.ky as string)}
  {@const cssClass = typeof ui2 === 'string' ? '' : (ui2.cssClass as string)}
  {@const field = defsGetField(ky)}
  {#if field}
    {#if mode === 'edit'}
      <div class="mb-2 flex grow">
        {#if field.tp === 'doc'}
          <UiDocNode {itemDoc} {ky} titleKy={field.titleKy} />
        {:else}
          <UiInputNode class={cssClass} {defs} {field} {itemDoc} {ky} />
        {/if}
      </div>
    {:else}
      {@render textNode(itemDoc?.item?.ival?.[ky] as string)}
    {/if}
  {/if}
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet textNode(txt: string, cssClass: string = '')}
  <div class="mb-2 flex grow {cssClass}">
    <div>{txt}</div>
  </div>
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet gutterActions(ui2: DefsUIGutter)}
  <div class="ml-3 mt-3 flex flex-col gap-2 mobile:ml-2">
    {#if Array.isArray(ui2.actions)}
      {#each ui2.actions as action, index (action + index)}
        {@render actionButton(action)}
      {/each}
    {/if}
  </div>
{/snippet}

<!-- ------------------------------------------------------------- -->

{#snippet actionButton(ui2: string)}
  {@const cfg = getActionButtonCfg(ui2)}
  {#if cfg}
    <button
      class="box-border rounded-full p-[7px] {cfg.button}"
      onclick={() => {
        clickAction(ui2)
      }}
    >
      <ZIcon class="flex size-[18px] {cfg.icon}" icon={cfg.component} />
    </button>
  {/if}
{/snippet}

<!-- ------------------------------------------------------------- -->

{#if Array.isArray(ui)}
  {@render arrayNode(ui)}
{:else if typeof ui === 'object' && ui.tp === 'panel'}
  {@render panelNode(ui)}
{:else if typeof ui === 'object' && ui.tp === 'section'}
  {@render sectionNode(ui)}
{:else if typeof ui === 'object' && ui.tp === 'row'}
  {@render rowNode(ui)}
{:else if typeof ui === 'object' && ui.join !== undefined}
  {@render joinNode(ui)}
{:else if typeof ui === 'object' && ui.ky !== undefined}
  {@render stringNode(ui)}
{:else if typeof ui === 'string' && defsGetField(ui)?.multi === true}
  {@render multiDataNode(ui)}
{:else if typeof ui === 'string'}
  {@render stringNode(ui)}
{:else}
  <LogPreview dat={ui} title="ui else" />
{/if}

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.pz-section-h
  :global(h2) // :global prevents unused css removal
    font-family _roboto, sans-serif
    font-size var(--pz-font-size-18)
    font-weight 700
    font-style normal
</style>
