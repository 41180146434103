import { gql } from '@urql/core'

import { type XVal } from '@utils/reactive'

import { graphqlMutationQuery, graphqlSubscriptionQuery } from '../../services/graphql'

import { Q_SUBSCRIBE_USER_DATA, Q_UPDATE_USER_DISPLAYNAME_AND_METADATA } from './qDefs'

// -------------------------------------------------------------------

export function gqlSubscribeAuthenticatedUserData (rf: XVal<unknown>, data: any = undefined): any {
  const q = gql`
    ${Q_SUBSCRIBE_USER_DATA}
  `

  function process (result: any): unknown {
    const res = result?.data?.user

    processRes(res, rf)

    return res
  }

  if (data !== undefined) {
    graphqlSubscriptionQuery(q, data, { callback: process })
  }

  return {
    q,
    process
  }
}

// -------------------------------------------------------------------

export function gqlMutateAuthenticatedUserData (rf: XVal<unknown>, data: any = undefined): any {
  const q = gql`
    ${Q_UPDATE_USER_DISPLAYNAME_AND_METADATA}
  `

  function process (result: any): unknown {
    const res = result?.data?.updateUser

    processRes(res, rf)

    return res
  }

  if (data !== undefined) {
    graphqlMutationQuery(q, data, { callback: process })
  }

  return {
    q,
    process
  }
}

// -------------------------------------------------------------------

function processRes (res: any, rf: XVal<unknown>): void {
  if (rf?.xval !== undefined) {
    if (res?.id !== undefined) {
      rf.xval = { ...(rf.xval ?? {}), ...res }
    }
  }
}
