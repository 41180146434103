import type { Component } from 'svelte'

import { callHooksDefs } from '@/modules/hooks'

// -------------------------------------------------------------------

export interface DefsField {
  title: string
  tp?: string
  multi?: boolean
  titleKy?: string
}

// -------------------------------------------------------------------

export interface DefsUIGutter {
  actions?: string[]
}

// -------------------------------------------------------------------

export interface DefsUIRecord {
  tp?: string
  title?: string
  icon?: Component
  gutter?: DefsUIGutter
  cssClass?: string
  join?: string[]
  ui?: DefsUI
  ky?: string
}

export type DefsUIArray = DefsUIRecord[] | Array<DefsUIRecord | string> | string[]

export type DefsUI = DefsUIRecord | DefsUIArray | string

// -------------------------------------------------------------------

export interface DefsView {
  list: {
    columns: string[]
  }
  view: DefsUIRecord
  edit: DefsUIRecord
}

// -------------------------------------------------------------------

export interface Defs {
  breed: string
  fields: Record<string, DefsField>
  views: DefsView
}

// -------------------------------------------------------------------

export async function loadDefs (breed: string): Promise<Defs | null> {
  return await callHooksDefs(breed)
}
