import { uuidB62ToUuidV7, uuidV7ToUuidB62 } from '@/utils/id'

import type { UuidB62, UuidV7 } from '@utils/id'

// -------------------------------------------------------------------

const ERROR = 'ERROR'
const UUIDB62: UuidB62 = '00000000000000123' as UuidB62
const UUIDV7: UuidV7 = '70000000-7000-7000-7000-700000000000' as UuidV7

// -------------------------------------------------------------------

type TestFunction = (tst: string) => Promise<string>

// -------------------------------------------------------------------

const tests: Record<string, TestFunction> = {
  diverseTestUuidB62ToUuidV7,
  diverseTestUuidV7ToUuidB62
}

// -------------------------------------------------------------------

export async function diverseRunTest (tst: string): Promise<string> {
  for (const [name, test] of Object.entries(tests)) {
    if (name === tst) {
      return await test(tst)
    }
  }
  return ''
}

// -------------------------------------------------------------------

export async function diverseTestUuidB62ToUuidV7 (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const uuidV7 = uuidB62ToUuidV7(UUIDB62)
    const uuidB62 = uuidV7ToUuidB62(uuidV7)
    resolve(
      uuidB62 === UUIDB62 && uuidV7.length === 36 && uuidB62.length >= 17 && uuidB62.length <= 22
        ? 'diverseTestUuidB62ToUuidV7'
        : ERROR
    )
  })
}

// -------------------------------------------------------------------

export async function diverseTestUuidV7ToUuidB62 (tst: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const uuidB62 = uuidV7ToUuidB62(UUIDV7)
    const uuidV7 = uuidB62ToUuidV7(uuidB62)
    resolve(
      uuidV7 === UUIDV7 && uuidV7.length === 36 && uuidB62.length >= 17 && uuidB62.length <= 22
        ? 'diverseTestUuidV7ToUuidB62'
        : ERROR
    )
  })
}
