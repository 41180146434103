<script lang="ts">
import type { Snippet } from 'svelte'

import { cn } from '../utils/shadcnUtils'

// -------------------------------------------------------------------

const { onclick, ...props } = $props()
const children = props.children as Snippet

const { title } = props

const className: string = typeof props.class === 'string' ? props.class : ''
</script>

<!-- ------------------------------------------------------------- -->

<button
  class={cn(
    'z-button min-h-10 rounded-full bg-primary px-5 py-2 text-base font-bold text-white hover:bg-primary-dark',
    className
  )}
  {onclick}
  onkeydown={(e) => {
    e.preventDefault()
    e.key === 'Enter' && onclick()
  }}
  tabindex="0"
>
  {#if children}
    {@render children()}
  {/if}

  {#if title}
    {title}
  {/if}
</button>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">

</style>
