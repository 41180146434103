import type { UuidB62 } from '@utils/id'

import { appState, injectState, type UseStateReturn } from './appState'

import { gqlCreateSpaceForUser, gqlGetAllUserSpaces } from './gql/gqlSpace'
// import type { SpaceDb } from '@utils/spaceDb'

// -------------------------------------------------------------------

interface SpaceUser {
  id: UuidB62
  rights: string
}

export type SpaceUsers = Record<UuidB62, SpaceUser>

export interface Space {
  id: UuidB62
  name: string
  users: SpaceUsers
  // db?: SpaceDb
}

export type Spaces = Record<UuidB62, Space>

let spaces: Spaces = $state({})

let activeSpace: Space | undefined = $state()

// -------------------------------------------------------------------

export interface SpaceState {
  spaces: Spaces
  isSpacesKnown: boolean
  activeSpace: Space | undefined
}

// -------------------------------------------------------------------

async function retrieveOrCreateSpaces (userIdB62: UuidB62): Promise<void> {
  // TODO Get from localStorage.

  let spacesFromLiveDb = await gqlGetAllUserSpaces(userIdB62, {
    networkOnly: true
  }) // TODO Catch errors. Including being offline.
  // console.log('useSpaces', spacesFromLiveDb)
  if (Object.keys(spacesFromLiveDb).length <= 0) {
    // We got no spaces from the live db, which should mean there is not one yet for the user. We need to create one.
    spacesFromLiveDb = await gqlCreateSpaceForUser(userIdB62, {
      networkOnly: true
    }) // TODO Catch errors. Including being offline.
    // console.log('useSpaces 222', spacesFromLiveDb)
  }
  if (Object.keys(spacesFromLiveDb).length <= 0) {
    console.error('useSpaces ERR: no spaces of getting from live db or creating one in the live db.', spacesFromLiveDb)
  } else {
    // We got spaces from the live DB. We need to merge it with local storage.
    // TODO Sync spaces with localStorage.
    // console.log('useSpaces 333', spacesFromLiveDb)
    spaces = spacesFromLiveDb
    // console.log('useSpaces 444', spaces)
  }

  if (Object.keys(spaces).length > 0) {
    activeSpace = Object.values(spaces)[0]
  }
}

// -------------------------------------------------------------------

export function useSpaces (): UseStateReturn {
  let lastUserIdB62: UuidB62 | undefined

  return {
    start: () => {
      $effect.root(() => {
        $effect(() => {
          if (appState.isLoggedInAndIdKnown === true) {
            const userIdB62 = appState.authenticatedUserUuidB62
            if (userIdB62 !== undefined && userIdB62 !== lastUserIdB62) {
              lastUserIdB62 = userIdB62

              // TODO Create tests.

              void retrieveOrCreateSpaces(userIdB62)
            }
          }
        })
      })
    },

    extendState () {
      injectState('spaces', () => spaces)
      injectState('isSpacesKnown', () => Object.keys(spaces).length > 0)
      injectState('activeSpace', () => activeSpace)
    }
  }
}
