<script lang="ts">
// import { t } from 'i18next'

import { ensuredDefined } from '@utils/error'
import { type UuidB62 } from '@utils/id'
import type { YDoc } from '@utils/y'

import { appState } from '../store/appState'

import { itemGetByIdOrCreateNew, itemGetNewId } from '../store/item'
import { type Space } from '../store/space'

import LogPreview from '../components/LogPreview'
import UiNode from '../components/UiNode'
import { type Defs, loadDefs } from '../defs/defs'
import { gotoUrl, gotoUrlWithoutChangingHistory, type UIMode, type UIParams } from '../routes'

// -------------------------------------------------------------------

const {
  params
}: {
  params: UIParams
} = $props()

let itemDoc: YDoc | undefined = $state()

// -------------------------------------------------------------------

let mode: UIMode = $state('view')

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  mode = params?.mode ?? 'view'
})

// -------------------------------------------------------------------

let defs: Defs | null = $state(null)

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  void loadDefs(params?.breed).then((loadedDefs) => (defs = loadedDefs))
})

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  if (params?.id !== undefined) {
    const breed: string = params?.breed
    const itemId: UuidB62 | 'new' = params?.id

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (itemId === 'new') {
      const newId = itemGetNewId()
      gotoUrlWithoutChangingHistory('/' + breed + '/' + newId + '/edit')
    } else if (itemDoc?.id !== itemId) {
      const activeSpace: Space = ensuredDefined(appState.activeSpace, 'appState.activeSpace not initialized')
      itemDoc = itemGetByIdOrCreateNew({ spaceId: activeSpace.id, breed, itemId, loadData: 'subscribe' })
    }
  }
})

// -------------------------------------------------------------------

function clickActionEdit (): void {
  gotoUrl('/' + params?.breed + '/' + params?.id + '/edit')
}
</script>

<!-- ------------------------------------------------------------- -->

<div>
  {#if itemDoc !== undefined && defs !== null}
    <UiNode {clickActionEdit} {defs} {itemDoc} {mode} ui={defs?.views?.[mode]?.ui ?? []} />
  {/if}
</div>

<!-- <h1>Item = { params.id } = { params.breed }</h1> -->

<br /><br /><br />
<LogPreview dat={params?.id} title="id" />
<LogPreview dat={params?.breed} title="breed" />
<!-- <LogPreview dat={ params?.mode } title="mode" /> -->
<!-- <LogPreview dat={ itemDoc?.item } title="itemDoc?.item" /> -->
<LogPreview dat={itemDoc} title="itemDoc" />

<!-- <LogPreview dat={ defs } title="defs" /> -->

<!-- ------------------------------------------------------------- -->

<style lang="stylus">

</style>
