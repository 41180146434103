<script lang="ts">
import { appState } from '../store/appState'

import AppSideMenuAdd from './AppSideMenuAdd.svelte'
import AppSideMenuMain from './AppSideMenuMain.svelte'

import ZIcon from './ZIcon.svelte'
import NuIconChevronLeft from './icons/NuIconChevronLeft.svelte'
import NuIconMenu from './icons/NuIconMenu.svelte'
import NuIconPlus from './icons/NuIconPlus.svelte'

// -------------------------------------------------------------------

let showMainMenu = $state(false)
let showAddMenu = $state(false)

// -------------------------------------------------------------------

function openMainMenu (): void {
  showMainMenu = true
}

// -------------------------------------------------------------------

function closeMainMenu (): void {
  showMainMenu = false
}

// -------------------------------------------------------------------

function openAddMenu (): void {
  showAddMenu = true
}

// -------------------------------------------------------------------

function closeAddMenu (): void {
  showAddMenu = false
}
</script>

<!-- ------------------------------------------------------------- -->

<header
  class="a-app-header flex flex-row bg-foreground p-4 text-white +-[.z-icon:hover]:text-primary +-[.z-icon]:text-white
    mobile:p-0.5 mobile:+-[.z-icon]:size-6"
>
  <div class="flex grow flex-row items-center">
    {#if appState.isReadyForAction}
      <!-- onclick="{ router.go(-1) }" -->
      <ZIcon icon={NuIconChevronLeft} />
    {/if}
  </div>

  <div class="nux-title nu-center-content nu-center-horz nu-overflow-shrink-ellipsis flex grow items-center">
    <h1 id="a-app-header-title" class="mt-1">Peanutz</h1>
  </div>

  <div class="a-app-header-area-right flex grow items-center justify-end +-[.z-icon:last-child]:mr-2">
    {#if appState.isReadyForAction}
      <div class="flex flex-row gap-2">
        <ZIcon class="a-app-header-add-menu" icon={NuIconPlus} onclick={openAddMenu} />
        <ZIcon class="a-app-header-main-menu-toggle" icon={NuIconMenu} onclick={openMainMenu} />
      </div>
    {/if}
  </div>
</header>

{#if showMainMenu}
  <AppSideMenuMain onClose={closeMainMenu} />
{/if}

{#if showAddMenu}
  <AppSideMenuAdd onClose={closeAddMenu} />
{/if}

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.a-app-header
  display grid
  grid-template-columns 1fr auto 1fr
</style>
