import { type Component } from 'svelte'
import router from 'page'

import { isAuthenticatedAsync } from './store/auth'

import Four04 from './views/Four04.svelte'
import Home from './views/Home.svelte'
import LoginView from './views/LoginView.svelte'
import ProfileView from './views/ProfileView.svelte'
import Test from './views/Test.svelte'

import TestIcons from './components/TestIcons.svelte'
import { callHooksRouteList } from './modules/hooks'
import type { UuidB62 } from './utils/id'

// -------------------------------------------------------------------

export interface LoginParams {
  isLogin: boolean
  isRegister: boolean
}

// -------------------------------------------------------------------

export interface TestParams {
  isTest: boolean
}

// -------------------------------------------------------------------

export type UIMode = 'view' | 'edit'

// -------------------------------------------------------------------

export interface UIParams {
  breed: string
  mode?: UIMode
  id?: UuidB62 | 'new'
}

// -------------------------------------------------------------------

export interface Route {
  path: string
  component: Component
  params?: LoginParams | TestParams | UIParams
  unauth?: boolean
}

// -------------------------------------------------------------------

function getRoutes (): Route[] {
  const routes: Route[] = [
    {
      path: '/',
      component: Home
    },
    {
      path: '/login',
      unauth: true,
      params: { isLogin: true, isRegister: false },
      component: LoginView as unknown as Component
    },
    {
      path: '/register',
      unauth: true,
      params: { isLogin: false, isRegister: true },
      component: LoginView as unknown as Component
    },
    {
      path: '/profile',
      component: ProfileView
    },
    {
      path: '/testicons',
      component: TestIcons
    },
    {
      path: '/test/:cat/:tst/:p1?/:p2?',
      params: { isTest: true },
      unauth: true,
      component: Test as unknown as Component
    }
  ]

  callHooksRouteList(routes)

  routes.push({
    path: '*',
    component: Four04
  })

  return routes
}

// -------------------------------------------------------------------

export function useRouter (): { page: any, params: any } {
  let page: Component | undefined = $state()
  let params = $state(null)

  // Loop around all of the routes and create a new instance of router for reach one with some rudimentary checks.
  getRoutes().forEach((route) => {
    router(
      route.path,

      // Set the params variable to the context.
      // We use this on the component initialization.
      (ctx, next) => {
        // console.log('🚀 ~ useRouter ~ ctx:', ctx)
        params = { ...ctx.params, ...route.params }
        next()
      },

      // Check if auth is valid. If so, set the page to the component
      // otherwise redirect to login.
      async () => {
        if (!(route.unauth ?? false) && !(await isAuthenticatedAsync())) {
          router.redirect('/login')
        } else {
          // console.log('🚀 ~ useRouter ~ page:', route.component)
          page = route.component
        }
      }
    )
  })

  // Set up the router to start and actively watch for changes
  router.start({
    // hashbang: true
  })

  return {
    get page () {
      // This will pass the reactive variable to the component.
      return page
    },
    get params () {
      // This will pass the reactive variable to the component.
      return params
    }
  }
}

// -------------------------------------------------------------------

export function gotoUrl (path: string): void {
  router(path)
}

// -------------------------------------------------------------------

export function gotoUrlWithoutChangingHistory (path: string): void {
  router.redirect(path)
}
