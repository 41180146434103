import { gql } from '@urql/core'

import type { UuidB62, UuidV7 } from '@utils/id'
import { newUuidV7, uuidB62ToUuidV7, uuidV7ToUuidB62 } from '@utils/id'

import type { QueryOpts } from '../../services/graphql'
import { graphqlMutationQuery, graphqlQuery } from '../../services/graphql'

import type { Spaces, SpaceUsers } from '../space'

import { Q_CREATE_SPACE_FOR_USER, Q_GET_ALL_USER_SPACES } from './qDefs'

// -------------------------------------------------------------------

export async function gqlGetAllUserSpaces (
  userUuidB62: UuidB62 | undefined = undefined,
  opts: QueryOpts = {}
): Promise<Spaces> {
  return await new Promise((resolve, reject) => {
    function process (result: Record<string, any>): void {
      if (result?.data?.spaces !== undefined) {
        resolve(spacesGqlToData((result?.data?.spaces ?? []) as unknown[]))
      } else {
        reject(result)
      }
    }

    const q = gql`
      ${Q_GET_ALL_USER_SPACES}
    `

    if (userUuidB62 !== undefined) {
      graphqlQuery(q, { userId: uuidB62ToUuidV7(userUuidB62) }, { ...opts, callback: process })
    } else {
      reject(new Error('gqlGetAllUserSpaces: userUuidB62 is null'))
    }
  })
}

// -------------------------------------------------------------------

export async function gqlCreateSpaceForUser (
  userUuidB62: UuidB62 | undefined = undefined,
  opts: QueryOpts = {}
): Promise<Spaces> {
  return await new Promise((resolve, reject) => {
    const q = gql`
      ${Q_CREATE_SPACE_FOR_USER}
    `

    function process (result: any): void {
      if (result?.data?.insert_spaces?.returning !== undefined) {
        resolve(spacesGqlToData((result?.data?.insert_spaces?.returning ?? []) as unknown[]))
      } else {
        reject(result)
      }
    }

    if (userUuidB62 !== undefined) {
      graphqlMutationQuery(
        q,
        { spaceId: newUuidV7(), name: '_NEW_', userId: uuidB62ToUuidV7(userUuidB62) },
        { ...opts, callback: process }
      )
    } else {
      reject(new Error('gqlCreateSpaceForUser: userUuidB62 is null'))
    }
  })
}

// -------------------------------------------------------------------

function spacesGqlToData (gqlData: any[]): Spaces {
  const data: Spaces = {}

  for (const space of gqlData) {
    const spaceId = uuidV7ToUuidB62(space.id as UuidV7)
    const users: SpaceUsers = {}

    for (const user of space.c_user_space) {
      const userId = uuidV7ToUuidB62(user.user_id as UuidV7)
      users[userId] = {
        id: userId,
        rights: user.rights
      }
    }

    data[spaceId] = {
      id: spaceId,
      name: space.name,
      users
    }
  }

  return data
}
