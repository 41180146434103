import { type AppState, injectState, type UseStateReturn } from './appState'

// -------------------------------------------------------------------

export interface ReadyForActionState {
  isReadyForAction: boolean
  isCheckingForLogin: boolean
}

// -------------------------------------------------------------------

export const useReadyState = (): UseStateReturn => {
  return {
    extendState (aState: AppState) {
      // injectState('isReadyForAction', () => aState.isLoggedInAndIdKnown && aState.isInstanceKnown && aState.isSpacesKnown && aState.activeSpace?.db)
      injectState(
        'isReadyForAction',
        () =>
          aState.isLoggedInAndIdKnown === true &&
          aState.isInstanceKnown === true &&
          aState.isSpacesKnown === true &&
          aState.userDb !== undefined
      )
      injectState(
        'isCheckingForLogin',
        () => aState.authState !== 'error' && aState.authState !== 'SIGNED_IN' && aState.authState !== 'SIGNED_OUT'
      )
    },

    start: () => {}
  }
}
