<script lang="ts">
import ZIcon from './ZIcon.svelte'
import NuIconPrayer from './icons/NuIconPrayer.svelte'
// import NuIconA from '@nutzui/nutzui/components/icons/NuIconA.vue'
// import NuIconAlignCenter from '@nutzui/nutzui/components/icons/NuIconAlignCenter.vue'
// import NuIconAlignJustify from '@nutzui/nutzui/components/icons/NuIconAlignJustify.vue'
// import NuIconAlignLeft from '@nutzui/nutzui/components/icons/NuIconAlignLeft.vue'
// import NuIconAlignRight from '@nutzui/nutzui/components/icons/NuIconAlignRight.vue'
// import NuIconArrowDoubleDown from '@nutzui/nutzui/components/icons/NuIconArrowDoubleDown.vue'
// import NuIconArrowDropdown from '@nutzui/nutzui/components/icons/NuIconArrowDropdown.vue'
// import NuIconArrowRight from '@nutzui/nutzui/components/icons/NuIconArrowRight.vue'
// import NuIconArrowRightCircle from '@nutzui/nutzui/components/icons/NuIconArrowRightCircle.vue'
// import NuIconAtSymbol from '@nutzui/nutzui/components/icons/NuIconAtSymbol.vue'
// import NuIconBold from '@nutzui/nutzui/components/icons/NuIconBold.vue'
// import NuIconChatBubbles from '@nutzui/nutzui/components/icons/NuIconChatBubbles.vue'
// import NuIconCheckmark from '@nutzui/nutzui/components/icons/NuIconCheckmark.vue'
// import NuIconChevronLeft from '@nutzui/nutzui/components/icons/NuIconChevronLeft.vue'
// import NuIconChevronRight from '@nutzui/nutzui/components/icons/NuIconChevronRight.vue'
// import NuIconClose from '@nutzui/nutzui/components/icons/NuIconClose.vue'
// import NuIconCloud from '@nutzui/nutzui/components/icons/NuIconCloud.vue'
// import NuIconCloudChecked from '@nutzui/nutzui/components/icons/NuIconCloudChecked.vue'
// import NuIconCog from '@nutzui/nutzui/components/icons/NuIconCog.vue'
// import NuIconFormatClear from '@nutzui/nutzui/components/icons/NuIconFormatClear.vue'
// import NuIconH1 from '@nutzui/nutzui/components/icons/NuIconH1.vue'
// import NuIconH2 from '@nutzui/nutzui/components/icons/NuIconH2.vue'
// import NuIconH3 from '@nutzui/nutzui/components/icons/NuIconH3.vue'
// import NuIconH4 from '@nutzui/nutzui/components/icons/NuIconH4.vue'
// import NuIconHelp from '@nutzui/nutzui/components/icons/NuIconHelp.vue'
// import NuIconHorizontalRule from '@nutzui/nutzui/components/icons/NuIconHorizontalRule.vue'
// import NuIconHouse from '@nutzui/nutzui/components/icons/NuIconHouse.vue'
// import NuIconItalic from '@nutzui/nutzui/components/icons/NuIconItalic.vue'
// import NuIconListBullet from '@nutzui/nutzui/components/icons/NuIconListBullet.vue'
// import NuIconListCheck from '@nutzui/nutzui/components/icons/NuIconListCheck.vue'
// import NuIconListNumbered from '@nutzui/nutzui/components/icons/NuIconListNumbered.vue'
// import NuIconLockClosed from '@nutzui/nutzui/components/icons/NuIconLockClosed.vue'
// import NuIconLogout from '@nutzui/nutzui/components/icons/NuIconLogout.vue'
// import NuIconMarker from '@nutzui/nutzui/components/icons/NuIconMarker.vue'
// import NuIconMenu from '@nutzui/nutzui/components/icons/NuIconMenu.vue'
// import NuIconMore from '@nutzui/nutzui/components/icons/NuIconMore.vue'
// import NuIconNote from '@nutzui/nutzui/components/icons/NuIconNote.vue'
// import NuIconPencil from '@nutzui/nutzui/components/icons/NuIconPencil.vue'
// import NuIconPhone from '@nutzui/nutzui/components/icons/NuIconPhone.vue'
// import NuIconPlus from '@nutzui/nutzui/components/icons/NuIconPlus.vue'
// import NuIconPlusCircle from '@nutzui/nutzui/components/icons/NuIconPlusCircle.vue'
// import NuIconSearch from '@nutzui/nutzui/components/icons/NuIconSearch.vue'
// import NuIconShieldAlert from '@nutzui/nutzui/components/icons/NuIconShieldAlert.vue'
// import NuIconStreetSign from '@nutzui/nutzui/components/icons/NuIconStreetSign.vue'
// import NuIconStrikethrough from '@nutzui/nutzui/components/icons/NuIconStrikethrough.vue'
// import NuIconTag from '@nutzui/nutzui/components/icons/NuIconTag.vue'
// import NuIconTask from '@nutzui/nutzui/components/icons/NuIconTask.vue'
// import NuIconTrash from '@nutzui/nutzui/components/icons/NuIconTrash.vue'
// import NuIconUserDetails from '@nutzui/nutzui/components/icons/NuIconUserDetails.vue'
// import NuIconUserGroup from '@nutzui/nutzui/components/icons/NuIconUserGroup.vue'
import NuIconUser from './icons/NuIconUser.svelte'

const icons = [
  [NuIconUser, NuIconPrayer]
  // [
  //   // NuIconChevronLeft,
  //   // NuIconChevronRight,
  //   // NuIconSearch,
  //   // NuIconMenu,
  //   // NuIconBold,
  //   // NuIconItalic,
  //   // NuIconStrikethrough,
  //   // NuIconArrowDropdown,
  //   // NuIconMarker
  // ],
  // [
  //   // NuIconMenu,
  //   // NuIconListBullet,
  //   // NuIconListCheck,
  //   // NuIconMore,
  //   // NuIconCheckmark,
  //   NuIconUser
  //   // NuIconPhone,
  //   // NuIconAtSymbol,
  //   // NuIconCloud,
  //   // NuIconCloudChecked
  // ],
  // [
  //   // NuIconMenu,
  //   // NuIconHelp,
  //   // NuIconLockClosed,
  //   // NuIconPencil,
  //   // NuIconPlusCircle,
  //   // NuIconArrowRightCircle,
  //   NuIconUser
  //   // NuIconUserGroup,
  //   // NuIconCog,
  //   // NuIconArrowDoubleDown
  // ],
  // [
  //   // NuIconListBullet,
  //   // NuIconListNumbered,
  //   // NuIconA,
  //   // NuIconAlignLeft,
  //   // NuIconAlignRight,
  //   // NuIconAlignCenter,
  //   // NuIconAlignJustify,
  //   // NuIconFormatClear,
  //   // NuIconPlus
  // ],
  // // [NuIconListBullet, NuIconH1, NuIconH2, NuIconH3, NuIconH4, NuIconHorizontalRule, NuIconClose, NuIconNote],
  // [
  //   // NuIconChatBubbles,
  //   // NuIconUserDetails,
  //   // NuIconShieldAlert,
  //   // NuIconTask,
  //   // NuIconHouse,
  //   // NuIconTrash,
  //   // NuIconArrowRight,
  //   // NuIconStreetSign,
  //   // NuIconTag,
  //   // NuIconLogout
  // ]
]
</script>

<div>
  {#each icons as _icons, _index (_index)}
    <div class="nux-wrapper2 flex">
      {#each _icons as icon, index (index)}
        <div class="icon-with-gridlines">
          <ZIcon {icon} />
          <div class="nux-line" />
          <div class="nux-line nux-line-bottom" />
          <div class="nux-line nux-line-left" />
          <div class="nux-line nux-line-right" />
        </div>
      {/each}

      <br /><br />
    </div>
  {/each}
</div>

<!-- <div class="nu-flex" v-for="(_icons, _index) in icons" :key="_index">
  <template v-for="(icon, index) in _icons" :key="index">
    <NuIcon class:even={index % 2}>
      <component :is="icon" />
    </NuIcon>
  </template>

  <br /><br />
</div>

<div class="nux-wrapper2 nu-flex" v-for="(_icons, _index) in icons" :key="_index">
  <div v-for="(icon, index) in _icons" :key="index">
    <NuIcon class="close" class:even={index % 2}>
      <component :is="icon" />
      <div class="nux-line nux-line-left" />
      <div class="nux-line nux-line-right" />
    </NuIcon>
  </div>
  <div class="nux-line" />
  <div class="nux-line nux-line-bottom" />
</div> -->

<style lang="stylus" scoped>
// :global(.z-icon)
//   margin-right 2px
//   background-color #eeexx
//   // &.even
//   //   background-color #ddd
//   &.close
//     margin-right -8px
//     background-color transparent !important

.nux-wrapper2
  transform scale(3)
  -webkit-transform-origin: 0 0;
  width: 300%;
  position relative
  margin-bottom 80px

  .icon-with-gridlines
    position relative
    width 32px
    height 32px
    :global(svg)
      z-index 2
      width 24px
      height 24px

  .nux-line
    position absolute
    top 6px
    // width 100%
    width 32px
    height 1px
    background-color red
    z-index 1
    &.nux-line-bottom
      top auto
      bottom 6px
    &.nux-line-left
      // top auto
      top 0
      left 6px
      width 1px
      // height 100%
      height 32px
    &.nux-line-right
      // top auto
      top 0
      right 6px
      width 1px
      // height 100%
      height 32px
</style>
