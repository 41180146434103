<script lang="ts">
import { t } from 'i18next'

import { type AddMenuList, callHooksAddMenuList } from '@/modules/hooks'

import { gotoUrl } from '../routes'

import AppSideMenu from './AppSideMenu.svelte'
import AppSideMenuList from './AppSideMenuList.svelte'
import AppSideMenuListItem from './AppSideMenuListItem.svelte'

// -------------------------------------------------------------------

const { onClose } = $props()

let sideMenuComponent: InstanceType<typeof AppSideMenu>

// -------------------------------------------------------------------

const addMenuList: AddMenuList[] = []

callHooksAddMenuList(addMenuList)

// -------------------------------------------------------------------

function goRouteAndClose (slug: string): void {
  gotoUrl(slug)
  sideMenuComponent.initClose()
}
</script>

<!-- ------------------------------------------------------------- -->

<div class="a-app-side-menu-main flex flex-col">
  <AppSideMenu bind:this={sideMenuComponent} {onClose} title={t('Add')}>
    <AppSideMenuList>
      {#each addMenuList as { icon, title, slug }, index (slug)}
        <AppSideMenuListItem
          {icon}
          onclick={() => {
            goRouteAndClose(slug)
          }}
          {title}
        />
      {/each}
    </AppSideMenuList>
  </AppSideMenu>
</div>
