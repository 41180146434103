// import { type ActionReturn } from 'svelte/action'

export function teleport (node: HTMLElement, name = 'teleport-container'): /* ActionReturn */ unknown {
  const teleportContainer = document.getElementById(name)
  teleportContainer?.appendChild(node)
  // teleportContainer.focus() // optional

  return {
    destroy () {
      node.remove()
    }
  }
}
