import { newUuidB62, type UuidB62 } from '@utils/id'

import { appState, injectState, type UseStateReturn } from './appState'

// -------------------------------------------------------------------

interface Instance {
  id: string
}

const instance: Instance = $state({
  id: ''
})

export interface InstanceState {
  instance: Instance
  instanceId: UuidB62 | ''
  isInstanceKnown: boolean
}

// -------------------------------------------------------------------

function retrieveOrCreateNewInstanceId (userIdB62: UuidB62): void {
  const storageKey = userIdB62 + '.instance_id'
  let storeInstanceId = localStorage.getItem(storageKey) ?? ''
  // TODO If there was a previous instance ID, handle the cleanup of that one.
  if (storeInstanceId === '') {
    storeInstanceId = newUuidB62()
    // console.log(
    //   'Instance ID NOT found in localStorage. Setting new',
    //   storeInstanceId
    // )
    localStorage.setItem(storageKey, storeInstanceId)
  }
  if (storeInstanceId !== instance.id) {
    // console.log('storeInstanceId !== instance.id')
    instance.id = storeInstanceId
  }
}

// -------------------------------------------------------------------

export function useInstance (): UseStateReturn {
  let lastUserIdB62: UuidB62 | undefined

  return {
    start: () => {
      $effect.root(() => {
        $effect(() => {
          if (appState.isLoggedInAndIdKnown === true) {
            const userIdB62: UuidB62 | undefined = appState.authenticatedUserUuidB62
            if (userIdB62 !== undefined && userIdB62 !== lastUserIdB62) {
              lastUserIdB62 = userIdB62

              // TODO Create tests.

              retrieveOrCreateNewInstanceId(userIdB62)
            }
          }
        })
      })
    },

    extendState () {
      injectState('instance', () => instance)
      injectState('instanceId', () => instance.id ?? '')
      injectState('isInstanceKnown', () => instance.id.length > 0)
    }
  }
}
