import { NhostClient } from '@nhost/nhost-js'

import { getHabitat } from '../store/env'

// -------------------------------------------------------------------

let nhost: NhostClient | undefined

// -------------------------------------------------------------------

export function getNhostClient (): NhostClient {
  if (nhost !== undefined) {
    return nhost
  }

  const habitat = getHabitat()

  // console.log('getNhostClient', habitat)

  // This will use/set nhostRefreshToken etc in Local storage.
  nhost = new NhostClient({
    subdomain: habitat.nhostSubdomain,
    region: habitat.nhostRegion
  })

  return nhost
}
