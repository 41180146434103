<script lang="ts">
const { dat, title }: { dat: unknown, title: string | undefined } = $props()

// -------------------------------------------------------------------

function getDat (): unknown {
  if (typeof dat === 'object') {
    return JSON.stringify(dat, (key, value) => (typeof value === 'undefined' ? ':value: :is: :undefined:' : value), 2)
  }

  return dat
}
</script>

<!-- ------------------------------------------------------------- -->

<pre><span>{title !== undefined ? title + ': ' : ''}</span>{getDat()}</pre>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
pre
 background-color #444
 color white
 font-size 9px
 line-height 11px
 font-family monospace
 max-height 200px
 overflow auto
 margin 2px 0
 padding 1px 4px

span
  color #b0b0ff
  font-weight bold
  font-size 11px

::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 9px;
}

::-webkit-scrollbar:horizontal {
    height: 9px;
}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 8px;
    background-color: #888;
    border 2px solid #444
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ddd;
}

</style>
