<script lang="ts">
import './app.css'
import './app.styl'

import { t } from 'i18next'

import { appState } from './store/appState'

import AppContent from './components/AppContent.svelte'
import AppHeader from './components/AppHeader.svelte'
import AppSideMenuWrapper from './components/AppSideMenuWrapper.svelte'
import { useRouter } from './routes'

// -------------------------------------------------------------------

// const testⵅ = 1
// const testⵙ = 1
// const testᐅ = 1

// -------------------------------------------------------------------

const rt = useRouter()

// -------------------------------------------------------------------

let waitingForReadyMs = $state(0)
const startMs = Date.now()

function waitReady (): void {
  if (appState.isReadyForAction === true) {
    return
  }

  waitingForReadyMs = Date.now() - startMs

  setTimeout(waitReady, 300)
}

waitReady()

// -------------------------------------------------------------------

const waitingTakesABbitLongerSoShowSpinnerMeanwhile = $derived(waitingForReadyMs > 1000)
const waitingTookTooLong = $derived(waitingForReadyMs > 30000) // 30000? (5000 for testing)

// -------------------------------------------------------------------

function isLoginOrRegisterOrTest (): boolean {
  return rt.params.isLogin === true || rt.params.isRegister === true || rt.params.isTest === true
}

// -------------------------------------------------------------------

const showDebugPane = false
</script>

<!-- ------------------------------------------------------------- -->

<div class="z-app-body flex grow flex-col">
  {#if appState.isReadyForAction}
    <AppHeader />

    {#if showDebugPane}
      <div class="flex grow flex-row">
        <AppContent>
          <svelte:component this={rt.page} params={rt.params} />
        </AppContent>
        <pre id="pz-debug-pane"><div>Debug pane</div></pre>
      </div>
    {:else}
      <AppContent>
        <svelte:component this={rt.page} params={rt.params} />
      </AppContent>
    {/if}

    <AppSideMenuWrapper />
  {:else if isLoginOrRegisterOrTest() && appState.isCheckingForLogin !== true}
    <div class="flex grow flex-col items-center justify-center">
      <svelte:component this={rt.page} params={rt.params} />
    </div>
  {:else}
    <div class="flex grow flex-col items-center justify-center">
      {#if waitingTookTooLong}
        <div>
          {t('Something went wrong while getting things ready!')}
          <br />
          {t('Try refreshing the page, restarting the browser or using another browser, like Chrome, Safari or Edge,')}
          <br />
          <!-- TODO Show diagnostic info? -->
          <br />
          isCheckingForLogin: {appState.isCheckingForLogin}<br />
          isReadyForAction: {appState.isReadyForAction}<br />
          isLoggedInAndIdKnown: {appState.isLoggedInAndIdKnown}<br />
          isInstanceKnown: {appState.isInstanceKnown}<br />
          isSpacesKnown: {appState.isSpacesKnown}<br />
          <!-- activeSpace?.db: {appState.activeSpace?.db}<br /> -->
          <!-- instance?.db: { appState.instance?.db }<br /> -->
          userDb: {appState.userDb}<br />
          authState: {appState.authState}<br />
          nhostUser: {appState.nhostUser}<br />
          nhostUser?.id: {appState.nhostUser?.id}<br />
        </div>
      {:else if waitingTakesABbitLongerSoShowSpinnerMeanwhile}
        <div aria-busy="true">
          &nbsp; {t('Getting things ready...')}
        </div>
      {:else}
        <div>...</div>
        <!-- waitingTakesABbitLongerSoShowSpinnerMeanwhile: { waitingTakesABbitLongerSoShowSpinnerMeanwhile }<br />
        waitingTookTooLong: { waitingTookTooLong }<br /> -->
      {/if}
    </div>
  {/if}

  <div class="alpha-msg">
    {appState.appVersion}
  </div>
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.z-app-body
  width 100%
  height 100%

.alpha-msg
  font-size: 11px;
  color: #888;
  background-color: #ffffffb3;
  font-weight: 500;
  right: 0;
  bottom: 4px;
  position: fixed;
  pointer-events: none;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 999999;

#pz-debug-pane
  background-color #444
  color white
  font-size 9px
  line-height 11px
  font-family monospace
  max-width min(300px, 30vw)
  max-height 90vh
  overflow auto
  margin 2px 0
  padding 1px 4px
  overflow-wrap break-word
  white-space pre-wrap

  & :global(> *)
    max-width 300px
    line-break anywhere
    white-space pre-wrap
    padding-bottom 2px;
    margin-bottom 2px;
    border-bottom 1px solid #888

  :global(.pz-dbg-cnt-node)
    margin-left 8px
    color pink
</style>
