<script lang="ts">
// import { sqliteRunTest } from '../test/sqliteTests'
import { untrack } from 'svelte'

import { dexieRunTest } from '../test/dexieTests'
import { diverseRunTest } from '../test/diverseTests'
import { itemRunTest } from '../test/itemTests'
import { yRunTest } from '../test/yTests'

// -------------------------------------------------------------------

const { params } = $props()

let testResult = $state('')

// -------------------------------------------------------------------

const runTest = async (): Promise<string> => {
  const tst: string = params?.tst
  if (params?.cat === 'y') {
    return await yRunTest(tst)
  }
  // if (params?.cat === 'sqlite') {
  //   return await sqliteRunTest(tst)
  // }
  if (params?.cat === 'dexie') {
    return await dexieRunTest(tst)
  }
  if (params?.cat === 'diverse') {
    return await diverseRunTest(tst)
  }
  if (params?.cat === 'item') {
    return await itemRunTest(tst, params)
  }
}

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  // Without untrack, the some tests, like the itemTest, would keep re-running somehow, causing an infinite loop.
  void untrack(async () => {
    try {
      testResult = await runTest()
    } catch (e) {
      testResult = e.message
    }
  })
})
</script>

<!-- ------------------------------------------------------------- -->

<h1>Test result</h1>

<div id="test-result">{testResult}</div>
