<script lang="ts">
import { t } from 'i18next'

import { appState } from '../store/appState'

import { updateAuthenticatedUserData } from '../store/auth'

// -------------------------------------------------------------------

let firstName = $state('')
let lastName = $state('')

// -------------------------------------------------------------------

const updateUserProfile = (event: Event): void => {
  event.preventDefault()

  if (appState.authenticatedUser !== undefined) {
    updateAuthenticatedUserData({
      id: appState.authenticatedUser.id,
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: {
        firstName,
        lastName
      }
    })
  }
}
</script>

<!-- ------------------------------------------------------------- -->

<div>
  <div class="text-error">
    So I started to walk into the water. I won't lie to you boys, I was terrified. But I pressed on, and as I made my
    way past the breakers a strange calm came over me. I don't know if it was divine intervention or the kinship of all
    living things but I tell you Jerry at that moment, I was a marine biologist.
  </div>
  <h1>Profile ...</h1>
  <br />
  {#if appState.authenticatedUser}
    <div>
      Hello, {appState.authenticatedUser?.displayName}. Your email is {appState.authenticatedUser?.email}.
      <br />

      <form onsubmit={updateUserProfile}>
        <input class="input" placeholder={t('First name')} v-model="firstName" bind:value={firstName} />
        <br />
        <input class="input" placeholder={t('Last name')} bind:value={lastName} />
        <br />

        <!-- :disabled="loading" -->
        <button className="m-3 text-sm btn">
          {t('Save')}
        </button>

        <!-- <div v-if="error">
          {{ error }}
        </div> -->
      </form>
    </div>
  {/if}
</div>
