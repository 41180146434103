import type { Component } from 'svelte'

import type { Defs } from '@/defs/defs'
import type { Route } from '@/routes.svelte'

// -------------------------------------------------------------------

export interface AddMenuList {
  icon: Component
  title: string
  slug: string
}

// -------------------------------------------------------------------

export interface MainMenuList {
  icon: Component
  title: string
  slug: string
}

// -------------------------------------------------------------------

type FnHooksAddMenuList = (addMenuList: AddMenuList[]) => void
type FnHooksMainMenuList = (mainMenuList: MainMenuList[]) => void
type FnHooksRouteList = (routeList: Route[]) => void
type FnHooksDefs = (breed: string) => Promise<Defs | null>

// -------------------------------------------------------------------

const hooksAddMenuList: FnHooksAddMenuList[] = []
const hooksMainMenuList: FnHooksMainMenuList[] = []
const hooksRouteList: FnHooksRouteList[] = []
const hooksDefs: FnHooksDefs[] = []

// -------------------------------------------------------------------

export function addHookAddMenuList (cb: FnHooksAddMenuList): void {
  hooksAddMenuList.push(cb)
}

// -------------------------------------------------------------------

export function callHooksAddMenuList (addMenuList: AddMenuList[]): void {
  for (const cb of hooksAddMenuList) {
    cb(addMenuList)
  }
}

// -------------------------------------------------------------------

export function addHookMainMenuList (cb: FnHooksMainMenuList): void {
  hooksMainMenuList.push(cb)
}

// -------------------------------------------------------------------

export function callHooksMainMenuList (mainMenuList: MainMenuList[]): void {
  for (const cb of hooksMainMenuList) {
    cb(mainMenuList)
  }
}

// -------------------------------------------------------------------

export function addHookRouteList (cb: FnHooksRouteList): void {
  hooksRouteList.push(cb)
}

// -------------------------------------------------------------------

export function callHooksRouteList (routeList: Route[]): void {
  for (const cb of hooksRouteList) {
    cb(routeList)
  }
}

// -------------------------------------------------------------------

export function addHookDefs (cb: FnHooksDefs): void {
  hooksDefs.push(cb)
}

// -------------------------------------------------------------------

export async function callHooksDefs (breed: string): Promise<Defs | null> {
  let def: Defs | null = null

  for (const cb of hooksDefs) {
    if (def == null) {
      def = await cb(breed)
    }
  }

  return def
}
