import { mande } from 'mande'

import { base64ToBytes, bytesToBase64 } from '@/utils/base64'
import type { UuidB62 } from '@/utils/id'

import type { Branded } from '@utils/brandedType'
import { type DB, type DbMorph, dexieProcessSrvMorphs, type InstanceClocks, instanceClocks } from '@utils/dexie'

import { getHabitat } from '../store/env'
import type { Space } from '../store/space'

import { log } from '../utils/debug'

// -------------------------------------------------------------------

type YmutBase64 = Branded<string, 'YmutBase64'>

interface SrvMorph {
  id: string
  instanceId: string
  spaceId: string
  folderId: string
  itemId: string
  breed: string
  ky: string
  instanceClock: number
  ymut: YmutBase64
}

// -------------------------------------------------------------------

interface MorphSrvSpace {
  space: Space
  lastPollTime: number
  pollInterval: number
}

interface MorphSrvClient {
  id: string
  spaces: Record<string, MorphSrvSpace>
}

export type MorphSrvClients = Record<string, MorphSrvClient>

// -------------------------------------------------------------------

interface MorphSrvGetSpaceMorphsResult {
  spaceId: string
  morphs: SrvMorph[]
}

// -------------------------------------------------------------------

interface MorphSrvDef {
  id: string
  pollInterval: number
}

// -------------------------------------------------------------------

export function morphSrvGetSrvIdForSpace (spaceId: UuidB62): MorphSrvDef {
  const habitat = getHabitat()

  if (habitat.dev) {
    return {
      id: 'http://127.0.0.1:3000',
      pollInterval: 1_000
    }
  } else {
    return {
      id: 'https://srv0.peanutz.com', // TODO Get from graphql.
      pollInterval: 10_000 // TODO Make this configurable.
    }
  }
}

// -------------------------------------------------------------------

export function morphSrvCreateClient (morphSrvDef: MorphSrvDef, db: DB): MorphSrvClient {
  const morphSrvClient: MorphSrvClient = {
    id: morphSrvDef.id,
    spaces: {}
  }

  morphSrvPoll(morphSrvClient, db)

  return morphSrvClient
}

// -------------------------------------------------------------------

function morphSrvCheckSpaceExists (srv: MorphSrvClient, space: Space): boolean {
  let exists = false

  if (srv.spaces !== undefined) {
    for (const srvSpace of Object.values(srv.spaces)) {
      if (srvSpace.space.id === space.id) {
        exists = true
        break
      }
    }
  }

  return exists
}

// -------------------------------------------------------------------

export function morphSrvCheckAddSpace (srv: MorphSrvClient, space: Space, morphSrvDef: MorphSrvDef): void {
  if (!morphSrvCheckSpaceExists(srv, space)) {
    srv.spaces[space.id] = {
      space,
      lastPollTime: 0,
      pollInterval: morphSrvDef.pollInterval
    }
  }
}

// -------------------------------------------------------------------

function morphSrvConvertDbMorphsToSrvMorphs (dbMorphs: DbMorph[]): SrvMorph[] {
  const srvMorphs: SrvMorph[] = []

  for (const dbMorph of dbMorphs) {
    srvMorphs.push({ ...(dbMorph as unknown as SrvMorph), ymut: bytesToBase64(dbMorph.ymut) as YmutBase64 })
  }

  return srvMorphs
}

// -------------------------------------------------------------------

function morphSrvConvertSrvMorphsToDbMorphs (srvMorphs: SrvMorph[]): DbMorph[] {
  const dbMorphs: DbMorph[] = []

  for (const srvMorph of srvMorphs) {
    dbMorphs.push({ ...(srvMorph as unknown as DbMorph), ymut: base64ToBytes(srvMorph.ymut) })
  }

  return dbMorphs
}

// -------------------------------------------------------------------

function morphSrvPoll (srv: MorphSrvClient, db: DB): void {
  const tm = Date.now()
  for (const srvSpace of Object.values(srv.spaces)) {
    if (tm - srvSpace.lastPollTime > srvSpace.pollInterval) {
      // TODO Do not start a new poll if the previous one (even for another space, but on the same server) is still running.
      // console.log('poll')
      srvSpace.lastPollTime = tm

      // Convert instanceClocks to an object with only the highest seen clocks.
      const clocks: Record<string, number> = {}
      for (const [key, instanceClock] of Object.entries(instanceClocks)) {
        clocks[key] = instanceClock.instanceClockHighestSeen
      }

      void morphSrvGetSpaceMorphs(srv.id, srvSpace.space.id, clocks).then((srvMorphs) => {
        const dbMorphs = morphSrvConvertSrvMorphsToDbMorphs(srvMorphs)
        void dexieProcessSrvMorphs(db, srvSpace.space.id, dbMorphs)
      })

      // break
    }
  }

  setTimeout(() => {
    morphSrvPoll(srv, db)
  }, 500)
}

// -------------------------------------------------------------------

function morphSrvGetMorphsUrl (url: string, spaceId: UuidB62): string {
  return url + '/morphs/' + spaceId + '/list'
}

// -------------------------------------------------------------------

async function morphSrvGetSpaceMorphs (
  url: string,
  spaceId: UuidB62,
  instanceClocks0: Record<string, number>
): Promise<SrvMorph[]> {
  const mandeFetch = mande(morphSrvGetMorphsUrl(url, spaceId))

  const res: MorphSrvGetSpaceMorphsResult = await mandeFetch.post({ instanceClocks: instanceClocks0 })
  // console.log('dexieSendSrvMorphs POST', res)

  return res?.morphs
}

// -------------------------------------------------------------------

export async function morphSrvSendSpaceMorphs (spaceId: UuidB62, dbMorphs: DbMorph[]): Promise<void> {
  const srvMorphs = morphSrvConvertDbMorphsToSrvMorphs(dbMorphs)

  const morphSrvDef = morphSrvGetSrvIdForSpace(spaceId)

  const mandeFetch = mande(morphSrvGetMorphsUrl(morphSrvDef.id, spaceId))

  const res = await mandeFetch.put({
    morphs: srvMorphs
  })

  log('dexieSendSrvMorphs POST ' + JSON.stringify(res))
  // console.log('dexieSendSrvMorphs POST', res)
}
