import type { AuthChangeEvent, NhostClient, NhostSession, SignInResponse, SignUpResponse, User } from '@nhost/nhost-js'
import { t } from 'i18next'

import type { UuidB62, UuidV7 } from '@utils/id'
import { uuidV7ToUuidB62 } from '@utils/id'
import { type XVal } from '@utils/reactive'

import { injectState } from './appState'
import { type UseStateReturn } from './appState'

import { getNhostClient } from '../services/nhost'

import { gqlMutateAuthenticatedUserData, gqlSubscribeAuthenticatedUserData } from './gql/gqlAuth'

// -------------------------------------------------------------------

export interface AuthState {
  authState: AuthStateType
  nhostUser: User | undefined
  authenticatedUser: User | undefined
  authenticatedUserUuidB62: UuidB62 | undefined
  isLoggedInAndIdKnown: boolean
}

// -------------------------------------------------------------------

const SIGNED_IN = 'SIGNED_IN'
const SIGNED_OUT = 'SIGNED_OUT'

type AuthStateType =
  | 'initial'
  | 'checking'
  | 'logging_in'
  | 'registering'
  | 'logging_out'
  | 'error'
  | typeof SIGNED_IN
  | typeof SIGNED_OUT

let authState: AuthStateType = $state('initial')
let nhostUser: User | undefined = $state()

let userSubscriptionStarted = $state(false)

let nhost: NhostClient

// -------------------------------------------------------------------

export function useAuth (): UseStateReturn {
  return {
    start: () => {
      authState = 'checking'
      // console.log('===authState', authState)
      nhost = getNhostClient()
      initAuthStateChange()
    },

    extendState () {
      injectState(
        'authState',
        () => authState,
        (val: AuthStateType) => {
          authState = val
        }
      )
      injectState(
        'nhostUser',
        () => nhostUser,
        (val: any) => {
          nhostUser = val
        }
      )
      injectState('authenticatedUser', () => nhostUser)
      injectState('authenticatedUserUuidB62', () =>
        (nhostUser?.id ?? '').length > 0 ? uuidV7ToUuidB62(nhostUser?.id as UuidV7) : undefined
      )
      injectState('isLoggedInAndIdKnown', () => authState === SIGNED_IN && nhostUser?.id !== undefined)
    }
  }
}

// -------------------------------------------------------------------

function initAuthStateChange (): void {
  nhost.auth.onAuthStateChanged((event: AuthChangeEvent, session: NhostSession | null) => {
    // console.log(
    //   `The auth state has changed. State is now ${event} with session: ${session}`,
    //   event,
    //   session
    // )

    if (event === SIGNED_IN) {
      // setTimeout(() => {
      // console.log('onAuthStateChanged 222', authState)
      authState = SIGNED_IN
      // console.log('===authState', authState)
      nhostUser = session?.user
      // }, 2000)

      subscribeAuthenticatedUser()
    } else if (event === SIGNED_OUT) {
      // console.log('onAuthStateChanged 333')
      authState = SIGNED_OUT
      // console.log('===authState', authState)
      nhostUser = undefined
    } else {
      authState = 'error'
      // console.log('===authState', authState)
      nhostUser = undefined
    }
  })
}

// -------------------------------------------------------------------

export async function signup (
  email: string,
  password: string,
  name: string
): Promise<SignUpResponse | { errorMsg: string, loginErrMsg?: string }> {
  authState = 'registering'
  // console.log('===authState', authState)

  const res: SignUpResponse = await nhost.auth.signUp({
    email,
    password,
    options: {
      displayName: name
    }
  })

  // console.log('signup', email, password, name, res)

  if (res?.error !== null) {
    authState = 'error'
    console.log('===authState', authState)

    if (res?.error?.message?.includes('length must be at least')) {
      return {
        errorMsg: t('The password must be at least 5 characters long.') // TODO Change the required length here and in the Nhost Settings Sign-In Methods.
      }
    } else if (res?.error?.error === 'email-already-in-use') {
      return {
        errorMsg: t('This email is already registered.'),
        loginErrMsg: t('Click here to login instead.')
      }
    } else {
      return {
        errorMsg: res?.error?.message
      }
    }
  }

  return res
}

// -------------------------------------------------------------------

export async function loginWithEmailAndPassword (
  email: string,
  password: string
): Promise<{ ok?: boolean, errorMsg?: string }> {
  authState = 'logging_in'
  console.log('===authState', authState)

  const res: SignInResponse = await nhost.auth.signIn({
    email,
    password
  })

  if (res?.session?.user?.id !== undefined) {
    return {
      ok: true
    }
  }

  if (res?.error?.error === 'already-signed-in') {
    // This can happen after registering. But should be no problem.
    // console.log('===already-signed-in')
    authState = SIGNED_IN
    // console.log('===authState222', authState)
    return {
      ok: true
    }
  }

  return {
    errorMsg: t('Login failed. Please try again.')
  }
}

// -------------------------------------------------------------------

export async function logout (): Promise<void> {
  authState = 'logging_out'
  console.log('===authState', authState)

  await nhost.auth.signOut()
}

// -------------------------------------------------------------------

export async function isAuthenticatedAsync (): Promise<boolean> {
  return await nhost.auth.isAuthenticatedAsync()
}

// -------------------------------------------------------------------

export function subscribeAuthenticatedUser (): void {
  if (!userSubscriptionStarted) {
    userSubscriptionStarted = true

    gqlSubscribeAuthenticatedUserData(getRfNhostUser(), { id: nhostUser?.id })
  }
}

// -------------------------------------------------------------------

export function updateAuthenticatedUserData (data: any): void {
  gqlMutateAuthenticatedUserData(getRfNhostUser(), data)
}

// -------------------------------------------------------------------

function getRfNhostUser (): XVal<User | undefined> {
  return {
    get xval () {
      return nhostUser
    },
    set xval (v) {
      nhostUser = v
    }
  }
}
