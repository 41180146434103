<script lang="ts">
import ZIcon from './ZIcon.svelte'
import NuIconChevronRight from './icons/NuIconChevronRight.svelte'

// -------------------------------------------------------------------

const { title, initClose } = $props()
</script>

<!-- ------------------------------------------------------------- -->

<div class="z-app-side-menu-header mb-5 flex flex-row +-[.z-icon]:-mr-4">
  <h1 class="grow">
    { title }
  </h1>

  <ZIcon icon={ NuIconChevronRight } onclick={ initClose } />
</div>
