<script lang="ts" setup>
import { sideMenuCloseAll, xSideMenuHasContent, xSideMenuOverlayClosing } from '../store/sideMenu'

// -------------------------------------------------------------------

const sideMenuHasContent: boolean = $derived(xSideMenuHasContent())
const sideMenuOverlayClosing = $derived(xSideMenuOverlayClosing())
</script>

<!-- ------------------------------------------------------------- -->

<div
  class="z-app-side-menu-overlay"
  class:z-app-side-menus-are-open={ sideMenuHasContent }
  class:z-app-side-menus-are-open-not-closing={ sideMenuHasContent && !sideMenuOverlayClosing }
  onclick={ sideMenuCloseAll }
  onkeydown={ (e) => {
    e.preventDefault()
    e.key === 'Enter' && sideMenuCloseAll()
  } }
  role="button"
  tabindex="0"
></div>

<div class="z-app-side-menu-wrapper">
  <div id="z-app-side-menu">
    <!-- This is a Teleport target/anchor. -->
  </div>
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
.z-app-side-menu-overlay
  position absolute
  left 0
  top 0
  width 0
  height 0
  background-color rgba(0, 0, 0, 0)
  backdrop-filter blur(6px) opacity(0)
  transition background-color var(--z-app-side-menu-open-duration) ease-in-out,
    backdrop-filter var(--z-app-side-menu-open-duration)
  z-index 100
  &.z-app-side-menus-are-open
    width 100%
    height 100%
  &.z-app-side-menus-are-open-not-closing
    // background-color rgba(100, 130, 160, 0.8)
    background-color rgba(0, 0, 0, 0.1)
    backdrop-filter blur(6px) opacity(1)

.z-app-side-menu-wrapper
  background-color red
</style>
