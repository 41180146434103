import { ensuredDefined } from '@utils/error'
import { type UuidB62 } from '@utils/id'

import { appState } from '../store/appState'

import { itemGetByIdOrCreateNew } from '../store/item'
import { type Space } from '../store/space'

// -------------------------------------------------------------------

const ERROR = 'ERROR'

// -------------------------------------------------------------------

type TestFunction = (tst: string, params: Record<string, unknown>) => Promise<string>

// -------------------------------------------------------------------

const tests: Record<string, TestFunction> = {
  itemTestShowItem
}

// -------------------------------------------------------------------

export async function itemRunTest (tst: string, params: Record<string, unknown>): Promise<string> {
  for (const [name, test] of Object.entries(tests)) {
    if (name === tst) {
      return await test(tst, params)
    }
  }
  return ''
}

// -------------------------------------------------------------------

export async function itemTestShowItem (tst: string, params: Record<string, unknown>): Promise<string> {
  return await new Promise((resolve, reject) => {
    const activeSpace: Space = ensuredDefined(appState.activeSpace, 'appState.activeSpace not initialized')

    const breed: string = params?.p1 as string // 'contact'
    const itemId: UuidB62 = params?.p2 as UuidB62 // '2VGWK3fwg24XCC90iXB2P' as UuidB62

    const itemDoc = itemGetByIdOrCreateNew({ spaceId: activeSpace.id, breed, itemId, loadData: true })

    const startMs = Date.now()

    function checkIvalHasKeys (): void {
      const waitingForReadyMs = Date.now() - startMs
      if (itemDoc?.item?.ival !== undefined && Object.keys(itemDoc?.item?.ival as Record<string, unknown>).length > 0) {
        resolve('itemTestShowItem: ' + JSON.stringify(itemDoc?.item?.ival, null, 2))
      } else if (waitingForReadyMs > 3000) {
        resolve(ERROR + ' timeout')
      } else {
        setTimeout(() => {
          checkIvalHasKeys()
        }, 10)
      }
    }

    checkIvalHasKeys()
  })
}

// TODO Add a test for when an item is changed in a different browser tab?
