import { mount } from 'svelte'
import i18next from 'i18next'

import App from './App.svelte'

// -------------------------------------------------------------------

void i18next.init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  // debug: true,
  resources: {
    en: {
      translation: {
        key: 'hello world'
      }
    }
  }
})

// -------------------------------------------------------------------

let app

const target = document.getElementById('app')
if (target !== null) {
  app = mount(App, { target })
}

// -------------------------------------------------------------------

export default app
