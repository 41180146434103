<script lang="ts">
import { onMount } from 'svelte'
import { Editor } from '@tiptap/core'
import { Collaboration } from '@tiptap/extension-collaboration'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
// import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
// import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
// import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import type * as Y from 'yjs'

import type { EditorState } from '@/utils/editor'

import { cn } from '../utils/shadcnUtils'

import ZEditorToolbar from './ZEditorToolbar.svelte'

// -------------------------------------------------------------------

let {
  ydoc = $bindable(),
  shouldFocus = false, // TODO Make focusable.
  callbackTitleChanged = undefined,
  title = '',
  ...props
}: {
  ydoc: Y.Doc
  shouldFocus?: boolean
  callbackTitleChanged?: (val: string) => void
  title: string
  class?: string
} = $props()

// -------------------------------------------------------------------

const className: string = typeof props.class === 'string' ? props.class : ''

let element: HTMLElement | null = $state(null)

let editor: Editor | null = $state(null)

let titleFromContent = $state('')

// -------------------------------------------------------------------

const editorState: EditorState = $state({
  isBold: false,
  isItalic: false,
  isTaskList: false,
  isBulletList: false,
  isOrderedList: false,
  isStrike: false,
  isHighlight: false,
  isTextStyle: false,
  isAlignLeft: false,
  isAlignCenter: false,
  isAlignRight: false,
  isAlignJustify: false
})

// -------------------------------------------------------------------

const titleIsDerivedFromContent: boolean = $derived(titleFromContent === title)

// -------------------------------------------------------------------

const UsingYjs = Collaboration.configure({
  document: ydoc
})

// -------------------------------------------------------------------

function getEditorTextContentForTitle (): string {
  // console.log('getEditorTextContentForTitle', editor2?.value?.view?.dom?.innerText?.split('\n'))
  let ret = ''

  const textContent = editor?.view?.dom?.innerText
  if (textContent != null) {
    const textLines = textContent.split('\n')
    const textLine = textLines[0].trim().substr(0, 80)
    ret = textLine
  }

  return ret
}

// -------------------------------------------------------------------

function editorCreated (): void {
  // console.log('editorCreated', editor2?.value?.view?.dom?.innerText?.split('\n'))

  updateTitleFromContent()
}

// -------------------------------------------------------------------

function editorUpdated (): void {
  // console.log('editorUpdated', editor2?.value?.view?.dom?.innerText?.split('\n'))

  const [isChanged, newTitle] = updateTitleFromContent()

  if (isChanged) {
    callbackTitleChanged?.(newTitle)
  }
}

// -------------------------------------------------------------------

function updateTitleFromContent (): [boolean, string] {
  let isChanged = false

  const newTitle = getEditorTextContentForTitle()

  if (titleIsDerivedFromContent && titleFromContent !== newTitle) {
    isChanged = true
  }

  titleFromContent = newTitle

  return [isChanged, newTitle]
}

// -------------------------------------------------------------------

// eslint-disable-next-line max-statements
function editorTransaction (): void {
  // console.log('onTransaction')
  if (editor !== null) {
    editorState.isBold = editor.isActive('bold')
    editorState.isItalic = editor.isActive('italic')
    editorState.isTaskList = editor.isActive('taskList')
    editorState.isBulletList = editor.isActive('bulletList')
    editorState.isOrderedList = editor.isActive('orderedList')
    editorState.isStrike = editor.isActive('strike')
    editorState.isHighlight = editor.isActive('highlight')
    editorState.isTextStyle = editor.isActive('textStyle')
    editorState.isAlignLeft = editor.isActive({ textAlign: 'left' })
    editorState.isAlignCenter = editor.isActive({ textAlign: 'center' })
    editorState.isAlignRight = editor.isActive({ textAlign: 'right' })
    editorState.isAlignJustify = editor.isActive({ textAlign: 'justify' })
  }
}

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
// eslint-disable-next-line max-lines-per-function
$effect(() => {
  // console.log('ZEditor', editor, element, ydoc)
  if (editor === null && element !== null && ydoc !== null) {
    editor = new Editor({
      element,

      // extensions: [
      //   Document,

      //   UsingYjs,

      //   StarterKit.configure({
      //     document: false,
      //     // The Collaboration extension comes with its own history handling
      //     // TODO Create own undo/redo based on the users own changes (not the changes of others.)
      //     history: false
      //   })
      // ],

      extensions: [
        // DocumentWithHeading,
        Document,

        UsingYjs,

        StarterKit.configure({
          document: false,
          // The Collaboration extension comes with its own history handling
          // TODO Create own undo/redo based on the users own changes (not the changes of others.)
          history: false
        }),

        // Placeholder.configure({
        //   placeholder: ({ node }) => {
        //     if (node.type.name === 'heading') {
        //       return t('What’s the title?') // TODO Should be computed.
        //     }

        //     return props.placeholder || t('Write anything...') // 'Write something...' // TODO Do we want this? What text? Should be computed.
        //   }
        // }),

        // Register the document with Tiptap
        // Collaboration.configure({
        //   // // document: props.xmlfragment,
        //   // document: props.xmlfragment,
        //   // field: 'doc'
        //   fragment: props.xmlfragment
        // }),

        // Document,
        // Paragraph,
        // Text,
        // Heading,

        TaskList,
        TaskItem,

        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Color,
        TextStyle,
        Highlight.configure({
          multicolor: true
        })
      ],

      // content: value,
      // onTransaction: () => {
      //   // force re-render so `editor.isActive` works as expected
      //   editor = editor
      // }
      // onUpdate: (props2: { editor: Editor, transaction: Transaction }) => {
      //   value = props2.editor.getHTML()
      // },

      // editable: props.editable,

      injectCSS: false,

      onUpdate () {
        // console.log('onUpdate', editor.getJSON())
        editorUpdated()
      },

      onCreate () {
        // console.log('CREATE', editor2?.value?.view?.dom?.innerText)
        editorCreated()
      },

      onTransaction: () => {
        editorTransaction()
      }
    })
  }
})

onMount(() => {
  // OnUnmount.
  return () => {
    // console.log('ZEditor unmount')
    if (editor !== null) {
      editor.destroy()
      editor = null
    }
  }
})
</script>

<!-- ------------------------------------------------------------- -->

<div class="grow flex-col rounded-[12px] border border-input focus-within:border-primary">
  {#if editor !== null}
    <ZEditorToolbar {editor} {editorState} showAdvanced={false} />
  {/if}
  <div class={cn('pz-doc-wrapper flex grow flex-col', className)}>
    <div
      bind:this={element}
      class="w-full px-4 py-4 focus-within:border-primary focus:outline-none
        focus:ring-0 +-[.ProseMirror]:outline-none"
    ></div>
  </div>
</div>

<!-- ------------------------------------------------------------- -->

<style lang="stylus">
// .nux-title-wrapper
//   margin-bottom var(--nu-sz-md)

// .nux-title
//   & :deep(input)
//     font-size var(--nu-h1-font-size)
//     font-weight 500
//     padding 20px 16px
//     &::placeholder
//       font-size var(--nu-h1-font-size)
//       font-weight 500
//       color var(--nu-cl-title-input-placeholder)

/* Basic editor styles */
:global(.ProseMirror)
  white-space pre-wrap

  // > * + *
    // margin-top: 0.75em;

  :global(p)
    margin 0
    line-height 1.4

  // :global(ul), :global(ol)
  //   padding: 0 1rem;

  :global(h1), :global(h2), :global(h3), :global(h4), :global(h5), :global(h6)
    line-height 1.2
    margin-top 12px
    margin-bottom 6px
    &:first-child
      margin-top 0

  :global(code)
    background-color: rgba(#616161, 0.1);
    color: #616161;

  :global(pre)
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    :global(code)
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;

  :global(mark)
    background-color: #FAF594;

  :global(img)
    max-width: 100%;
    height: auto;

  :global(hr)
    margin: 1rem 0;

  :global(blockquote)
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);

  :global(hr)
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;

  :global(ul)
    list-style: disc;
    list-style-position: inside;
    :global(li)
      :global(p)
        display: inline-flex
        margin 0 -0.2rem

  :global(ol)
    list-style: decimal;
    list-style-position: inside;
    :global(li)
      :global(p)
        display: inline-flex
        // margin 0
        // padding 0
        margin 0 0.3rem

  :global(ul[data-type="taskList"])
    list-style: none;
    padding: 0;
    :global(li)
      display: flex;
      align-items: center;
      :global(p)
        // margin 0
        margin 0 0.3rem
        padding 0
        line-height 1
      :global(input)
        height auto !important
        padding 0 !important
        margin 0 !important
      > :global(label)
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
        transform: translate(0, 1px) !important;
        accent-color var(--nu-cl-focus) !important
      > :global(div)
        flex: 1 1 auto;

/* Placeholder (at the top) */
// .ProseMirror p.is-editor-empty:first-child::before
// .ProseMirror .is-empty:first-child::before
/* Placeholder (on every new line) */
:global(.ProseMirror) .is-empty::before
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
</style>
