import type { Component } from 'svelte'
import { t } from 'i18next'

import type { Defs } from '@/defs/defs'
import Item from '@/views/Item.svelte'
import ItemList from '@/views/ItemList.svelte'

import { addHookAddMenuList, addHookDefs, addHookMainMenuList, addHookRouteList } from './hooks'

import NuIconPrayer from '@icons/NuIconPrayer.svelte'

// -------------------------------------------------------------------

export function prayerModuleInit (): void {
  addHookAddMenuList((addMenuList) => {
    addMenuList.push({
      icon: NuIconPrayer,
      title: t('Add new prayer'),
      slug: '/prayer/new/edit'
    })
  })

  addHookMainMenuList((mainMenuList) => {
    mainMenuList.push({
      icon: NuIconPrayer,
      title: t('Prayers'),
      slug: '/prayers'
    })
  })

  addHookRouteList((routes) => {
    routes.push({
      path: '/prayers',
      params: { breed: 'prayer' },
      component: ItemList as unknown as Component
    })
    routes.push({
      path: '/prayer/:id/:mode?',
      params: { breed: 'prayer' },
      component: Item as unknown as Component
    })
  })

  addHookDefs(async (breed): Promise<Defs | null> => {
    if (breed === 'prayer') {
      const { getDef } = await import('@/defs/prayer/prayerDefs')
      return getDef()
    }

    return null
  })
}
