<script lang="ts">
import type { DefsField } from '@/defs/defs'
import type { YDoc } from '@/utils/y'

import { objectToFlatKeys } from '@utils/object'

import { itemProcessChange } from '../store/item'

import ZInput from './ZInput'

// import LogPreview from '../components/LogPreview'

// -------------------------------------------------------------------

const {
  itemDoc,
  /* defs, */
  field,
  ky,
  shouldFocus = false,
  callbackInputCleared = undefined,
  ...props
}: {
  itemDoc: YDoc
  defs?: unknown
  field: DefsField
  ky: string
  shouldFocus?: boolean
  callbackInputCleared?: (ky: string) => void
  class?: string
} = $props()

const className: string = typeof props.class === 'string' ? props.class : ''

let dataReactive: string = $state('')

let _lastDataValue: string

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  // TODO How to watch only changes to itemDoc.item?
  // console.log('itemDoc.item.ival', itemDoc?.item?.ival)

  const obj = objectToFlatKeys(itemDoc?.item?.ival as Record<string, unknown>)
  dataReactive = (obj?.[ky] as string) ?? ''
})

// -------------------------------------------------------------------

function valChanged (val: string): void {
  changeDataReactive(val)
}

// -------------------------------------------------------------------

function changeDataReactive (v: string): void {
  dataReactive = v

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (dataReactive !== _lastDataValue || _lastDataValue === undefined) {
    itemProcessChange(itemDoc, { [ky]: dataReactive })
    _lastDataValue = dataReactive
  }
}
</script>

<!-- ------------------------------------------------------------- -->

<!-- <LogPreview dat={ itemDoc } title="itemDoc" />
<LogPreview dat={ defs } title="defs" />
<LogPreview dat={ ui } title="ui" /> -->

<!-- ------------------------------------------------------------- -->

<ZInput
  class={className}
  callbackData={ky}
  callbackInputCleared={callbackInputCleared as (callbackData: unknown) => void}
  callbackValChanged={valChanged}
  placeholder={field?.title}
  {shouldFocus}
  value={dataReactive}
/>
