<script lang="ts">
import { onMount } from 'svelte'
import type * as Y from 'yjs'

import { objectToFlatKeys } from '@/utils/object'
import { type YDoc, yDocGetSubDoc } from '@/utils/y'

import { itemProcessChange, itemProcessYSubDocUpdate } from '../store/item'

import ZEditor from './ZEditor.svelte'

// import LogPreview from '../components/LogPreview'

// -------------------------------------------------------------------

const {
  itemDoc,
  ky,
  titleKy,
  shouldFocus = false
}: {
  itemDoc: YDoc
  ky: string
  titleKy?: string
  shouldFocus?: boolean
} = $props()

let ydoc: Y.Doc | null = $state(null)

let titleReactive: string = $state('')

let _lastTitleValue: string

// -------------------------------------------------------------------

// OnMount and on every data change. Use $effect(untrack(() => { ... })) to only run once at mount.
$effect(() => {
  // TODO How to watch only changes to itemDoc.item?

  if (ydoc !== null) {
    ydocRemoveListener()
  }

  ydoc = yDocGetSubDoc(itemDoc, ky)

  ydoc?.on('update', onYdocUpdate)

  if (titleKy !== undefined) {
    const obj = objectToFlatKeys(itemDoc?.item?.ival as Record<string, unknown>)
    titleReactive = (obj?.[titleKy] as string) ?? ''
  }
})

// -------------------------------------------------------------------

onMount(() => {
  // OnUnmount.
  return () => {
    ydocRemoveListener()
  }
})

// -------------------------------------------------------------------

function onYdocUpdate (update: Uint8Array, origin: unknown): void {
  // console.log('onYdocUpdate', update, origin)

  itemProcessYSubDocUpdate({ doc: itemDoc, update, origin, ky })
}

// -------------------------------------------------------------------

function ydocRemoveListener (): void {
  if (ydoc !== null) {
    ydoc.off('update', onYdocUpdate)
  }
}
// -------------------------------------------------------------------

function titleChanged (val: string): void {
  changeTitleReactive(val)
}

// -------------------------------------------------------------------

function changeTitleReactive (v: string): void {
  titleReactive = v

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (titleKy !== undefined && (titleReactive !== _lastTitleValue || _lastTitleValue === undefined)) {
    itemProcessChange(itemDoc, { [titleKy]: titleReactive })
    _lastTitleValue = titleReactive
  }
}
</script>

<!-- ------------------------------------------------------------- -->

<!-- <LogPreview dat={ itemDoc } title="itemDoc" />
<LogPreview dat={ defs } title="defs" />
<LogPreview dat={ ui } title="ui" /> -->

<!-- ------------------------------------------------------------- -->

{#if ydoc !== null}
  <ZEditor callbackTitleChanged={titleChanged} {shouldFocus} title={titleReactive} {ydoc} />
{/if}
